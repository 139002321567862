import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../features/footer/Footer";
import Navbar from "../../../features/navbar/Navbar";
import axios from "axios";
import "./ProductPage.css";
import Slider from "react-slick";

function ProductPage() {
  const { pk, id, name } = useParams();
  const [productdata, setProductdata] = useState({});
  const [activeMedia, setActiveMedia] = useState({}); // Changed from activeImg to activeMedia
  const [imageset, setImageset] = useState([]);
  const [count, setCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [cartAdded, setCartAdded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // To track if the modal is open
  const [isUserConfirmed, setIsUserConfirmed] = useState(false); // To track user's confirmation
  const modalRef = useRef(null);
  useEffect(() => {
    const checkProductInCart = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/cart_finder/${pk}/${id}/`);
        if (response.data.value) {
          setCartAdded(true);
        }
      } catch (error) {
        console.error("Error checking cart status:", error);
      }
    };

    checkProductInCart();
  }, [pk, id]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const fetchSearchResults = async () => {
        try {
          await axios.get(
            `https://terracharms.com/user_search_get/${pk}/`,
            {
              params: { Search: searchTerm }
            }
          );
          navigate(`/user/allproducts/${pk}/${name}?search=${searchTerm}`);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };
      fetchSearchResults();
    }
  }, [searchTerm, pk, navigate]);

  useEffect(() => {
    const getProductDataById = async () => {
      try {
        let response = await axios.get(`https://terracharms.com/get_product/${pk}/${id}/`);
        if (!response.data) {
          throw new Error("Failed to fetch product data");
        }
        let product = response.data;
        setProductdata(product);
        setActiveMedia({ id: 1, type: 'image', url: product.product_image });
  
        setImageset([
          { id: 1, type: 'image', url: product.product_image },
          { id: 2, type: 'image', url: product.product_image2 },
          { id: 3, type: 'image', url: product.product_image3 },
          { id: 4, type: 'image', url: product.product_image4 },
          { 
            id: 5, 
            type: product.product_video.includes('youtube') ? 'youtube' : 'video', 
            url: product.product_video 
          }, // Check if it's a YouTube URL
        ]);
      } catch (error) {
        console.error("Error fetching product data:", error);
        toast.error("Failed to fetch product details");
      }
    };
    getProductDataById();
    window.scroll(0, 0);
  }, [pk, id]);

  const settings1 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  const mobileSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const fetchRecentSearched = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching recent searched products:', error);
      }
    };
    fetchRecentSearched();
  }, [pk]);

  // const addtoCart = async () => {
  //   try {
  //     const userConfirmed = window.confirm("Do you want to add the product?");
  //     if (!userConfirmed) return; // Exit if user cancels
     
  //     const response = await axios.patch(`https://terracharms.com/add_to_cart/${pk}/${id}/`);
  //     if (!response.data) {
  //       throw new Error("Failed to add item to cart");
  //     }
  //     setCartAdded(true); // Update cartAdded state after successful API call
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error adding item to cart:", error);
  //     toast.error("Failed to add item to cart");
  //   }
  // };
  // const addtoCart = async () => {
  //   try {
  //     // Show the modal instead of window.confirm()
  //     setIsModalOpen(true);

  //     // Wait for the user confirmation (This is just mimicking window.confirm)
  //     if (!isUserConfirmed) return;

  //     const response = await axios.patch(`https://terracharms.com/add_to_cart/${pk}/${id}/`);
  //     if (!response.data) {
  //       throw new Error("Failed to add item to cart");
  //     }
  //     setCartAdded(true); // Update cartAdded state after successful API call
  //     window.location.reload(); // Reload the page to reflect the updated cart
  //   } catch (error) {
  //     console.error("Error adding item to cart:", error);
  //     toast.error("Failed to add item to cart");
  //   }
  // };

  // // Handle confirmation action (Yes)
  // const confirmAction = () => {
  //   setIsUserConfirmed(true);
  //   setIsModalOpen(false);
  // };

  // // Handle cancellation action (No)
  // const cancelAction = () => {
  //   setIsUserConfirmed(false);
  //   setIsModalOpen(false);
  // };
  const addtoCart = async () => {
    try {
      // Show the modal to confirm the action
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };
  
  // Handle confirmation action (Yes)
  const confirmAction = async () => {
    try {
      setIsModalOpen(false); // Close the modal
      const response = await axios.patch(`https://terracharms.com/add_to_cart/${pk}/${id}/`);
      if (!response.data) {
        throw new Error("Failed to add item to cart");
      }
      setCartAdded(true); // Update cartAdded state after successful API call
      window.location.reload(); // Reload the page to reflect the updated cart
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };
  
  // Handle cancellation action (No)
  const cancelAction = () => {
    setIsModalOpen(false); // Close the modal without any further action
  };

  const handleQuantityChange = (amount) => {
    if (count + amount > 0) {
      setCount(count + amount);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: productdata.product_name,
        url: `https://terracharms.com/get_product/${pk}/${id}/`
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const discountPercentage = productdata.actual_price && productdata.discount_price
    ? ((productdata.actual_price - productdata.discount_price) / productdata.actual_price * 100).toFixed(2)
    : null;

    const handleCartButtonClick = () => {
      if (cartAdded) {
        navigate(`/Cart/${pk}/${name}/`);
      } else {
        addtoCart();
      }
    };

  const handleProductClick = (id, index) => {
    handleRecentSearchedClick(id);
    window.location.reload();
  };

  const handleRecentSearchedClick = (id) => {
    navigate(`/user/productpage/${pk}/${id}/${name}/`);
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const handleImageClick = (item) => {
    if (item.type === 'youtube') {
      // If the clicked item is a YouTube video, set the active media to show the video thumbnail
      setActiveMedia({ id: item.id, type: 'youtube', url: item.url });
    } else {
      setActiveMedia(item); // Set active media (image or video)
    }
  };
  const getYoutubeVideoId = (url) => {
    const regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  
  // Close the modal if clicked outside of the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
    modal: {
      backgroundColor: 'white',
      padding: '30px', // Increased padding for better spacing
      borderRadius: '10px',
      textAlign: 'center',
      width: '350px', // Increased width for better button spacing
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)', // More prominent shadow
      transition: 'all 0.3s ease-in-out',
    },
    modalText: {
      fontSize: '18px', // Slightly larger font size for better readability
      marginBottom: '25px', // More spacing between text and buttons
      color: '#333',
      fontWeight: 'bold', // Bold text for emphasis
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack buttons vertically
      gap: '10px', // Set gap between buttons
      width: '100%', // Ensure buttons fill container width
    },
    confirmButton: {
      backgroundColor: '#4CAF50', // Green
      color: 'white',
      border: 'none',
      padding: '12px 25px', // Larger padding for buttons
      borderRadius: '5px',
      cursor: 'pointer',
      marginBottom:'5px',
      transition: 'background-color 0.3s ease',
      fontSize: '16px', // Font size adjustment for better readability
      width: '100%', // Make button fill width of container
    },
    cancelButton: {
      backgroundColor: '#f44336', // Red
      color: 'white',
      border: 'none',
      padding: '12px 25px', // Larger padding for buttons
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      fontSize: '16px', // Font size adjustment for better readability
      width: '100%', // Make button fill width of container
    },
    confirmButtonHover: {
      backgroundColor: '#45a049', // Darker green on hover
    },
    cancelButtonHover: {
      backgroundColor: '#e53935', // Darker red on hover
    },
  };
  const [cartStatus, setCartStatus] = useState(Array(products.length).fill(false)); // Initialize with false
  return (
    <>
     <Navbar onSearch={setSearchTerm} />
      <div className="mt-6"></div>
      <div className="productpage"> 
  <div className="pc1">
  <div className="c11"> 
  <div className="imgset">
    {imageset.map((item) => (
      <div
        className="imgsmall h-full w-full"
        key={item.id}
        onClick={() => handleImageClick(item)} // No changes here
      >
        {item.type === 'image' ? (
          <img
            src={`https://terracharms.com/${item.url}`}
            alt={`Image ${item.id}`}
            className={activeMedia.id === item.id ? "active" : ""}
          />
        ) : item.type === 'youtube' ? (
          <img
            src={`https://img.youtube.com/vi/${getYoutubeVideoId(item.url)}/0.jpg`} // Displaying YouTube thumbnail
            alt="YouTube Video"
            className={activeMedia.id === item.id ? "active" : ""}
          />
        ) : (
          <video className="w-full h-full">
            <source
              src={`${item.url}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    ))}
  </div>

      <div className="imgbig">
        <div className="zoom-container">
          <div className="">
            {activeMedia.type === 'video' ? (
              <video controls className="active-video">
                <source
                  src={`https://terracharms.com/${activeMedia.url}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : activeMedia.type === 'youtube' ? (
              <iframe
                width="100%"
                height="500px"
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(activeMedia.url)}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                src={`https://terracharms.com/${activeMedia.url}`}
                alt={`Image ${activeMedia.id}`}
                className="active-image"
              />
            )}
          </div>
          <button className="share-button" onClick={handleShare}>
            <i className="fas fa-share-alt"></i>
          </button>
        </div>
      </div>
    </div>
  {/* </div>
</div> */}
          <div className='c12'>
            <h5 className='text-2xl text-gray-900 font-semibo'>{productdata.product_name}</h5>
            <div className="price-container">
              <p className="price">
                ₹{(productdata.discount_price * count).toFixed(2)}
                <span className="actual-price">₹{(productdata.actual_price * count).toFixed(2)}</span>
              </p>
              {discountPercentage && (
                <span className="discount-box">
                  {discountPercentage}% OFF
                </span>
              )}
            </div>
            {/* <div className="btncont">
  {productdata.in_stock > 1 ? (
    <>
      <button onClick={handleCartButtonClick}>
        {cartAdded ? "Go to Cart" : "Add to Cart"}
        {isModalOpen && (
        <div className="custom-overlay">
          <div className="custom-modal">
            <p>Do you want to add the product to your cart?</p>
            <div>
              <button onClick={confirmAction} className="confirm">Yes</button>
              <button onClick={cancelAction} className="cancel">No</button>
            </div>
          </div>
        </div>
      )}
      </button>
       {/* Custom Modal for Confirmation 
      
      <Link to={`/Cart/${pk}/${name}/`}>
        <button onClick={addtoCart}>Buy Now</button>
      </Link>
    </>
  ) : (
    <p className="out-of-stock-message">Out of Stock</p>
  )}
</div> */}
  {/* <div className="btncont">
      {productdata.in_stock > 1 ? (
        <>
          <button onClick={handleCartButtonClick}>
            {cartAdded ? "Go to Cart" : "Add to Cart"}
          </button>
          {isModalOpen && (
            <div style={modalStyles.overlay}>
              <div ref={modalRef} style={modalStyles.modal}>
                <p style={modalStyles.modalText}>
                  Do you want to add the product to your cart?
                </p>
                <div>
                  <button
                    onClick={confirmAction}
                    style={modalStyles.confirmButton}
                  >
                    Yes
                  </button>
                  <button
                    onClick={cancelAction}
                    style={modalStyles.cancelButton}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Custom Modal for Confirmation 
          <Link to={`/Cart/${productdata.pk}/${productdata.name}/`}>
            <button onClick={addtoCart}>Buy Now</button>
          </Link>
        </>
      ) : (
        <p className="out-of-stock-message">Out of Stock</p>
      )}
    </div> */}

<div className="btncont">
    {productdata.in_stock > 1 ? (
      <>
        <button onClick={handleCartButtonClick}>
          {cartAdded ? "Go to Cart" : "Add to Cart"}
        </button>
        {isModalOpen && (
          <div style={modalStyles.overlay}>
            <div ref={modalRef} style={modalStyles.modal}>
              <p style={modalStyles.modalText}>
                Do you want to add the product to your cart?
              </p>
              <div>
                <button
                  onClick={confirmAction}
                  style={modalStyles.confirmButton}
                >
                  Yes
                </button>
                <button
                  onClick={cancelAction}
                  style={modalStyles.cancelButton}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
        <Link to={`/Cart/${productdata.pk}/${productdata.name}/`}>
          <button onClick={addtoCart}>Buy Now</button>
        </Link>
      </>
    ) : (
      <p className="out-of-stock-message">Out of Stock</p>
    )}
  </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
  <div>
    <h1 className="font-bold text-1xl text-gray-900">Description</h1>
    <div className="space-y-6">
      <p className="text-base text-gray-900 ml-2 break-words overflow-hidden">{productdata.description}</p>
    </div>
  </div>
  <div className="mt-10">
    <h1 className="font-bold text-gray-900">Highlights</h1>
    <div className="space-y-6">
      <p className="text-base ml-2 text-gray-900 break-words overflow-hidden">{productdata.Highlights}</p>
    </div>
  </div>
  <div className="mt-10">
    <h1 className="font-bold text-gray-900">Details</h1>
    <div className="space-y-6">
      <p className="text-gray-900 ml-2 text-base break-words overflow-hidden">{productdata.Details}</p>
    </div>
  </div>
</div>
                           <div class="trust-badges">
   <div class="badge">
     <img src="/images/secure.jpeg" alt="Secure Transaction" />
     <p>Safe Payment</p>
   </div>
   <div class="badge">
     <img src="/images/returnable.jpeg" alt="7-Day Replacement" />
     <p>7 Day Return</p>
   </div>
   <div class="badge">
     <img src="/images/war.png" alt="Manufacturer Warranty" />
     <p>Warranty Covered</p>
   </div>
 </div>

           <hr /> 
          </div>
        </div>
      </div>
      <>
      {products.length > 0 && (
        <>
          {/* Desktop Version */}
          <div className="relative overflow-hidden mb-4 mx-4 mt-4 sm:mx-6 lg:mx-8 lg:block hidden">
            <div className="pt-4 bg-gray-100">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">
                You Also May Like
              </h2>
              <div className="mt-2 mb-1">
                <Slider {...settings1}>
                  {products.map((product, index) => (
                    <div className="p-2" key={product.id}>
                      <div
                        className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
                        onClick={() => handleProductClick(product.id, index)}
                      >
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                          <img
                            alt={product.product_name}
                            src={`https://terracharms.com${product.product_image}`}
                            className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                          />
                        </div>
                        <div className="mt-4 text-center">
                          <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                            {product.product_name}
                          </h3>
                          <div className="price-container mt-2">
                            <p className="price text-sm text-gray-600">
                              ₹{(product.discount_price * count).toFixed(2)}{" "}
                              <span className="actual-price line-through text-gray-500">
                                ₹{(product.actual_price * count).toFixed(2)}
                              </span>
                            </p>
                            {product.discount_price && product.actual_price && (
                              <span className="discount-box text-sm text-white">
                                {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          {/* Mobile Version */}
          <div className="bg-gradient-to-r from-lime-100 to-emerald-300 mt-2 mx-1 sm:mx-1 lg:hidden">
            <div className="pt-2 bg-gradient-to-r from-lime-100 to-emerald-300">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">You Also May Like</h2>
              <div className="mt-6 mb-14">
                <Slider {...mobileSettings}>
                  {products.map((product, index) => (
                    <div className="p-2" key={product.id}>
                      <div
                        className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
                        onClick={() => handleProductClick(product.id, index)}
                      >
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                          <img
                            alt={product.product_name}
                            src={`https://terracharms.com${product.product_image}`}
                            className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                          />
                        </div>
                        <div className="mt-4 text-center">
                          <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                            {product.product_name}
                          </h3>
                          <div className="price-container mt-2">
                            <p className="price text-sm text-gray-600">
                              ₹{(product.discount_price * count).toFixed(2)}{" "}
                              <span className="actual-price line-through text-gray-500">
                                ₹{(product.actual_price * count).toFixed(2)}
                              </span>
                            </p>
                            {product.discount_price && product.actual_price && (
                              <span className="discount-box text-sm text-white">
                                {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                              </span>
                            )}
                          </div>
                          {/* <button className="mt-2 text-sm text-blue-600">
                            {cartStatus[index] ? "Go to Cart" : "Add to Cart"}
                          </button> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
      <Footer />
    </>
  );
}
export default ProductPage;
