import React, { useState, useEffect } from 'react';
import './invoice.css';
import axios from 'axios';
import { Link, useParams, useNavigate } from "react-router-dom";

const Invoice = () => {
    const [orderdata, setOrderData] = useState(null);
    const { pk, orderId , name} = useParams();  // Get `pk` and `orderId` from URL params
    const navigate = useNavigate(); // Initialize the navigate function

    useEffect(() => {
        if (orderId && pk) {
            const fetchOrderDetails = async () => {
                try {
                    const response = await axios.get(`https://terracharms.com/orderitem_invoice/${pk}/${orderId}/`);
                    console.log('Response from API:', response.data);
                    setOrderData(response.data);
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            };

            fetchOrderDetails();
        }
    }, [pk, orderId]);


    if (!orderdata) {
        return <div>Loading...</div>;
    }
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with 0 if needed
    const monthIndex = date.getMonth(); // Get month index (0-11)
    const year = date.getFullYear(); // Get full year

    // Array of month names
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const month = monthNames[monthIndex]; // Get full month name

    return `${day} ${month} ${year}`; // Return formatted date
};
    return (
        <div className='OrdersSuccessful'>
            <Link to={`/user/yourorders/${pk}/${name}/`}>
                <button className='popup__close-btn'>
                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                    </svg>
                </button>
            </Link>

            <div className='confirmationcont'>
                <div className='c1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                    </svg>
                    <h2>Order Successful</h2>
                </div>

                <div className='c2'>
                    <h2>Order Summary</h2><br></br>
                    <div>
    <p>Order Date:</p>
    <p>{formatDate(orderdata.Order_Placed_Date)}</p>
</div>

                    <div>
                        <p>Shipping Address:</p>
                        <p>{orderdata.Shipping_Address}</p>
                    </div>
                </div>

                <div className='c3'>
                    <table>
                        <thead>
                            <tr>
                                <th>Sno.</th>
                                <th>Product Image</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>
                        {/* <Link to={`/user/productpage/${pk}/${product.id}/${name}/`}> */}
                        <div className='product-image-container'>
                            <img src={`https://terracharms.com/${orderdata.product_image}`} alt="Product" className='product-image' />
                        </div>
                        {/* </Link> */}
                    </td>
                                <td>{orderdata.product_name}</td>
                                <td>₹ {orderdata.actual_price}</td>
                                <td>{orderdata.quantity}</td>
                                <td>₹ {orderdata.Total_price}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='totalcont'>
                    <div>
                        <p>Total</p>
                        <p>₹ {orderdata.Total_price}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
