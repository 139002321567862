import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import './sidebar.css'; // Import CSS file

const Sidebar = () => {
  const { pk } = useParams();
  return (
    <aside className="navbar-aside" id="offcanvas_aside">
        <div className="">
          <Link to={`/admin/${pk}/`} className="brand-wrap">
            <img
              src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
              className="h-16"
              alt="Ecommerce dashboard template"
            /> <h2 className="font-bold">The StarkMart</h2>
        </Link>
        <button className="btn btn-icon btn-aside-minimize">
          <i className="text-muted fas fa-stream"></i>
        </button>
      </div>

    
        <ul className="menu-aside">
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/${pk}`} exact>
              <i className="icon fas fa-home"></i>
              <span className="text">Dashboard</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/products/${pk}`}>
              <i className="icon fas fa-shopping-bag"></i>
              <span className="text">Products</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/addproduct/${pk}`}>
              <i className="icon fas fa-cart-plus"></i>
              <span className="text">Add product</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/approve/${pk}`}>
              <i className="icon fas fa-store-alt"></i>
              <span className="text">Approved</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/category/${pk}`}>
              <i className="icon fas fa-list"></i>
              <span className="text">Categories</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/orders/${pk}`}>
              <i className="icon fas fa-bags-shopping"></i>
              <span className="text">Orders</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/promo/${pk}`}>
              <i className="icon fa fa-bullhorn"></i>
              <span className="text">Promotions</span>
            </NavLink>
          </li>
        
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/top/${pk}`}>
              <i className="icon fa fa-bars"></i>
              <span className="text">Top Selection</span>
            </NavLink>
          </li>
           <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/banner/${pk}`}>
              <i className="icon fa fa-bars"></i>
              <span className="text">Banners</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/users/${pk}`}>
              <i className="icon fas fa-user"></i>
              <span className="text">Users</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink activeClassName="active" className="menu-link" to={`/admin/sellers/${pk}`}>
              <i className="icon fas fa-store-alt"></i>
              <span className="text">Sellers</span>
            </NavLink>
          </li>          
        </ul>
      
    </aside>
  );
};

export default Sidebar;
