// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import './UserAddress.css';
// // import { useParams, Link } from 'react-router-dom';
// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';

// // const AUserAddress = () => {
// //     const [show, setShow] = useState(false);
// //     const [addresses, setAddresses] = useState([]);
// //     const [formData, setFormData] = useState({
// //         name: '',
// //         address: '',
// //         city: '',
// //         country: 'India',
// //         state: '',
// //         zipcode: '',
// //     });
// //     const [isEditing, setIsEditing] = useState(false);
// //     const [editAddressId, setEditAddressId] = useState(null);

// //     const { pk, name } = useParams();

// //     useEffect(() => {
// //         const fetchAddresses = async () => {
// //             try {
// //                 const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //                 setAddresses(response.data);
// //             } catch (error) {
// //                 console.error('Error fetching addresses:', error);
// //             }
// //         };

// //         fetchAddresses();
// //     }, [pk]);

// //     const handleInputChange = (e) => {
// //         const { name, value } = e.target;
// //         if (name === 'zipcode' && value.length > 6) return;

// //         setFormData((prevFormData) => ({
// //             ...prevFormData,
// //             [name]: value,
// //         }));
// //     };

// //     const handleSave = async () => {
// //         try {
// //             let response;
// //             if (isEditing) {
// //                 response = await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
// //                 toast.success('Address updated successfully');
// //             } else {
// //                 response = await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
// //                 toast.success(response.data.msg || 'Address added successfully');
// //             }
// //             setShow(false);
// //             setIsEditing(false);
// //             setEditAddressId(null);
// //             setFormData({
// //                 name: '',
// //                 address: '',
// //                 city: '',
// //                 country: 'India',
// //                 state: '',
// //                 zipcode: '',
// //             });
// //             const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //             setAddresses(addressResponse.data);
// //         } catch (error) {
// //             console.error('Error saving address:', error);
// //             toast.error('Failed to save address');
// //         }
// //     };

// //     const [confirmToastId, setConfirmToastId] = useState(null);

// //     // Custom toast for confirm actions with single popup restriction
// //     const showConfirmToast = (action, id) => {
// //         // Prevent multiple toasts
// //         if (confirmToastId) return;

// //         const toastId = toast.info(
// //             <div>
// //                 <p>Are you sure you want to {action} this address?</p>
// //                 <button
// //                     onClick={() => handleConfirmedAction(action, id)}
// //                     style={{
// //                         backgroundColor: 'green',
// //                         color: 'white',
// //                         margin: '0 5px',
// //                         padding: '5px 10px',
// //                         borderRadius: '5px',
// //                         border: 'none',
// //                         cursor: 'pointer',
// //                     }}
// //                 >
// //                     Yes
// //                 </button>
// //                 <button
// //                     onClick={() => {
// //                         toast.dismiss(toastId);
// //                         setConfirmToastId(null);
// //                     }}
// //                     style={{
// //                         backgroundColor: 'red',
// //                         color: 'white',
// //                         margin: '0 5px',
// //                         padding: '5px 10px',
// //                         borderRadius: '5px',
// //                         border: 'none',
// //                         cursor: 'pointer',
// //                     }}
// //                 >
// //                     No
// //                 </button>
// //             </div>,
// //             {
// //                 position: "top-center",
// //                 autoClose: false,
// //                 closeOnClick: false,
// //                 closeButton: false,
// //                 onClose: () => setConfirmToastId(null),
// //             }
// //         );
// //         setConfirmToastId(toastId);
// //     };


// //     const handleConfirmedAction = async (action, id) => {
// //         if (action === "delete") {
// //             try {
// //                 await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
// //                 toast.success('Address deleted successfully');
// //                 const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //                 setAddresses(addressResponse.data);
// //             } catch (error) {
// //                 console.error('Error deleting address:', error);
// //                 toast.error('Failed to delete address');
// //             }
// //         } else if (action === "edit") {
// //             const addressToEdit = addresses.find((addr) => addr.id === id);
// //             if (addressToEdit) {
// //                 setFormData(addressToEdit);
// //                 setIsEditing(true);
// //                 setEditAddressId(id);
// //                 setShow(true);
// //             }
// //         }
// //         toast.dismiss();  // Dismiss the confirmation toast
// //     };

// //     const handleDelete = (id) => {
// //         showConfirmToast("delete", id);
// //     };

// //     const handleEdit = (address) => {
// //         showConfirmToast("edit", address.id);
// //     };

// //     return (
// //         <div className="useraddress">
// //             <ToastContainer />
// //             <div className='button-header'>
// //                 <div className="content-header">
// //                     <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white ml-6 mt-4">
// //                         Back To Profile
// //                     </Link>
// //                 </div>
// //                 <div className="content-header">
// //                     <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white mr-6 mt-4">
// //                         Back To Home
// //                     </Link>
// //                 </div>
// //             </div>

// //             {!show && <h1 className="mainhead1">Your Address</h1>}
// //             {!show && (
// //                 <div className="addressin">
// //                     {addresses.map((item, index) => (
// //                         <div className="address" key={index}>
// //                             <span className='mt-1'>Name:{item.name}</span>
// //                             <span>Address: {item.address}</span>
// //                             <span>State: {item.state}</span>
// //                             <span>City: {item.city}</span>
// //                             <span>zipcode: {item.zipcode}</span>
// //                             <span>Country: {item.country}</span>
                           
// //                             <div className="editbtn mt-10 flex items-center cursor-pointer" onClick={() => handleEdit(item)}> 
// //                                 <p className="mr-2">Edit</p>
// //                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
// //                                     <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 3.487a2.25 2.25 0 013.182 3.183L7.265 19.45a4.5 4.5 0 01-1.73 1.047l-4.5 1.35a.75.75 0 01-.92-.92l1.35-4.5a4.5 4.5 0 011.047-1.73L16.862 3.487z" />
// //                                 </svg>
// //                             </div>

// //                             <div className="delbtn flex items-center cursor-pointer" onClick={() => handleDelete(item.id)}> 
// //                                 <p className="mr-2">Delete</p>
// //                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
// //                                     <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
// //                                 </svg>
// //                             </div>
// //                         </div>
// //                     ))}
// //                 </div>
// //             )}

// //             {!show && (
// //                 <div className="addnewbtn" onClick={() => setShow(true)}>
// //                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
// //                         <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
// //                     </svg>
// //                 </div>
// //             )}

// //             {show && ( 
// //                 <div className="addnew">
// //                     <h1 className="mainhead1">{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
// //                     <div className="form">
// //                         <div className="form-group">
// //                             <label htmlFor="name">Name</label>
// //                             <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
// //                         </div>
// //                         <div className="form-group">
// //                             <label htmlFor="address">Address</label>
// //                             <input type="text" name="address" value={formData.address} onChange={handleInputChange} />
// //                         </div>
// //                         <div className="form-group">
// //                             <label htmlFor="city">City</label>
// //                             <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
// //                         </div>
// //                         <div className="form-group">
// //                             <label htmlFor="state">State</label>
// //                             <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
// //                         </div>
// //                         <div className="form-group">
// //                             <label htmlFor="zipcode">Zip Code</label>
// //                             <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
// //                         </div>
// //                         <div className="form-group">
// //                             <label htmlFor="country">Country</label>
// //                             <input type="text" name="country" value="India" readOnly />
// //                         </div>
// //                         <button onClick={handleSave} className="btn btn-primary">{isEditing ? 'Update' : 'Save'}</button>
// //                     </div>
// //                 </div>
// //             )}
// //         </div>
// //     );
// // };

// // export default AUserAddress;


// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import './UserAddress.css';
// // import { useParams, Link } from 'react-router-dom';
// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';

// // const AUserAddress = () => {
// //     const [show, setShow] = useState(false);
// //     const [addresses, setAddresses] = useState([]);
// //     const [formData, setFormData] = useState({
// //         name: '',
// //         address: '',
// //         city: '',
// //         country: 'India',
// //         state: '',
// //         zipcode: '',
// //     });
// //     const [isEditing, setIsEditing] = useState(false);
// //     const [editAddressId, setEditAddressId] = useState(null);
// //     const [autoFillData, setAutoFillData] = useState({
// //         city: '',
// //         state: '',
// //         country: 'India',
// //         zipcode: '',
// //     });

// //     const { pk, name } = useParams();

// //     useEffect(() => {
// //         const fetchAddresses = async () => {
// //             try {
// //                 const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //                 setAddresses(response.data);
// //             } catch (error) {
// //                 console.error('Error fetching addresses:', error);
// //             }
// //         };

// //         fetchAddresses();
// //     }, [pk]);

// //     const handleInputChange = (e) => {
// //         const { name, value } = e.target;
// //         if (name === 'zipcode' && value.length > 6) return;

// //         setFormData((prevFormData) => ({
// //             ...prevFormData,
// //             [name]: value,
// //         }));
// //     };

// //     const handleSave = async () => {
// //         try {
// //             let response;
// //             if (isEditing) {
// //                 response = await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
// //                 toast.success('Address updated successfully');
// //             } else {
// //                 response = await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
// //                 toast.success(response.data.msg || 'Address added successfully');
// //             }
// //             setShow(false);
// //             setIsEditing(false);
// //             setEditAddressId(null);
// //             setFormData({
// //                 name: '',
// //                 address: '',
// //                 city: '',
// //                 country: 'India',
// //                 state: '',
// //                 zipcode: '',
// //             });
// //             const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //             setAddresses(addressResponse.data);
// //         } catch (error) {
// //             console.error('Error saving address:', error);
// //             toast.error('Failed to save address');
// //         }
// //     };

//     // const handleKeyDown = async (e) => {
//     //     // Handle Enter key press to fetch address suggestions
//     //     if (e.key === 'Enter' && e.target.name === 'address') {
//     //         const address = formData.address.trim();
//     //         if (address) {
//     //             try {
//     //                 const response = await axios.get(`https://terracharms.com/address_suggestions/`, {
//     //                     params: { address }, // Pass the trimmed address
//     //                 });
//     //                 const suggestions = response.data.suggestions;
//     //                 if (suggestions && suggestions.length > 0) {
//     //                     const { city, state, zipcode, country } = suggestions[0];
//     //                     setAutoFillData({
//     //                         city,
//     //                         state,
//     //                         country,
//     //                         zipcode,
//     //                     });
    
//     //                     setFormData((prevData) => ({
//     //                         ...prevData,
//     //                         city,
//     //                         state,
//     //                         zipcode,
//     //                         country: 'India',
//     //                     }));
//     //                 } else {
//     //                     toast.error('No suggestions found');
//     //                 }
//     //             } catch (error) {
//     //                 console.error('Error fetching address suggestions:', error);
//     //                 toast.error('Failed to fetch address suggestions');
//     //             }
//     //         } else {
//     //             toast.error('Please enter a valid address');
//     //         }
//     //     } else if (e.key === 'Enter') {
//     //         // Navigate to the next field on Enter key press
//     //         const currentIndex = ['name', 'address', 'city', 'state', 'zipcode'].indexOf(e.target.name);
//     //         const nextIndex = currentIndex + 1;
//     //         const nextField = document.querySelector(`[name=${['name', 'address', 'city', 'state', 'zipcode'][nextIndex]}]`);
//     //         if (nextField) {
//     //             nextField.focus();
//     //         }
//     //     }
//     // };
    

// //     const [confirmToastId, setConfirmToastId] = useState(null);

// //     // Custom toast for confirm actions with single popup restriction
// //     const showConfirmToast = (action, id) => {
// //         // Prevent multiple toasts
// //         if (confirmToastId) return;

// //         const toastId = toast.info(
// //             <div>
// //                 <p>Are you sure you want to {action} this address?</p>
// //                 <button
// //                     onClick={() => handleConfirmedAction(action, id)}
// //                     style={{
// //                         backgroundColor: 'green',
// //                         color: 'white',
// //                         margin: '0 5px',
// //                         padding: '5px 10px',
// //                         borderRadius: '5px',
// //                         border: 'none',
// //                         cursor: 'pointer',
// //                     }}
// //                 >
// //                     Yes
// //                 </button>
// //                 <button
// //                     onClick={() => {
// //                         toast.dismiss(toastId);
// //                         setConfirmToastId(null);
// //                     }}
// //                     style={{
// //                         backgroundColor: 'red',
// //                         color: 'white',
// //                         margin: '0 5px',
// //                         padding: '5px 10px',
// //                         borderRadius: '5px',
// //                         border: 'none',
// //                         cursor: 'pointer',
// //                     }}
// //                 >
// //                     No
// //                 </button>
// //             </div>,
// //             {
// //                 position: "top-center",
// //                 autoClose: false,
// //                 closeOnClick: false,
// //                 closeButton: false,
// //                 onClose: () => setConfirmToastId(null),
// //             }
// //         );
// //         setConfirmToastId(toastId);
// //     };

// //     const handleConfirmedAction = async (action, id) => {
// //         if (action === "delete") {
// //             try {
// //                 await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
// //                 toast.success('Address deleted successfully');
// //                 const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
// //                 setAddresses(addressResponse.data);
// //             } catch (error) {
// //                 console.error('Error deleting address:', error);
// //                 toast.error('Failed to delete address');
// //             }
// //         } else if (action === "edit") {
// //             const addressToEdit = addresses.find((addr) => addr.id === id);
// //             if (addressToEdit) {
// //                 setFormData(addressToEdit);
// //                 setIsEditing(true);
// //                 setEditAddressId(id);
// //                 setShow(true);
// //             }
// //         }
// //         toast.dismiss();  // Dismiss the confirmation toast
// //     };

// //     const handleDelete = (id) => {
// //         showConfirmToast("delete", id);
// //     };

// //     const handleEdit = (address) => {
// //         showConfirmToast("edit", address.id);
// //     };

// //     return (
// //         <div className="useraddress">
// //             <ToastContainer />
// //             <div className='button-header'>
// //                 <div className="content-header">
// //                     <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white ml-6 mt-4">
// //                         Back To Profile
// //                     </Link>
// //                 </div>
// //                 <div className="content-header">
// //                     <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white mr-6 mt-4">
// //                         Back To Home
// //                     </Link>
// //                 </div>
// //             </div>

// //             {!show && <h1 className="mainhead1">Your Address</h1>}
// //             {!show && (
// //                 <div className="addressin">
// //                     {addresses.map((item, index) => (
// //                         <div className="address" key={index}>
// //                             <span className='mt-1'>Name:{item.name}</span>
// //                             <span>Address: {item.address}</span>
// //                             <span>State: {item.state}</span>
// //                             <span>City: {item.city}</span>
// //                             <span>zipcode: {item.zipcode}</span>
// //                             <span>Country: {item.country}</span>
// //                             <div className="editbtn mt-10 flex items-center cursor-pointer" onClick={() => handleEdit(item)}> 
// //                                 <p className="mr-2">Edit</p>
// //                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
// //                                     <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 3.487a2.25 2.25 0 013.182 3.183L7.265 19.45a4.5 4.5 0 01-1.73 1.047l-4.5 1.35a.75.75 0 01-.92-.92l1.35-4.5a4.5 4.5 0 011.047-1.73L16.862 3.487z" />
// //                                 </svg>
// //                             </div>

// //                             <div className="delbtn flex items-center cursor-pointer" onClick={() => handleDelete(item.id)}> 
// //                                 <p className="mr-2">Delete</p>
// //                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
// //                                     <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
// //                                 </svg>
// //                             </div>
// //                         </div>
// //                     ))}
// //                 </div>
// //             )}

// //             {show && (
// //                 <div className="addressinput">
// //                     <input
// //                         type="text"
// //                         name="name"
// //                         value={formData.name}
// //                         onChange={handleInputChange}
// //                         onKeyDown={handleKeyDown}
// //                         placeholder="Name"
// //                     />
// //                     <input
// //                         type="text"
// //                         name="address"
// //                         value={formData.address}
// //                         onChange={handleInputChange}
// //                         onKeyDown={handleKeyDown}
// //                         placeholder="Address"
// //                     />
// //                     <input
// //                         type="text"
// //                         name="city"
// //                         value={formData.city || autoFillData.city}
// //                         onChange={handleInputChange}
// //                         onKeyDown={handleKeyDown}
// //                         placeholder="City"
// //                     />
// //                     <input
// //                         type="text"
// //                         name="state"
// //                         value={formData.state || autoFillData.state}
// //                         onChange={handleInputChange}
// //                         onKeyDown={handleKeyDown}
// //                         placeholder="State"
// //                     />
// //                     <input
// //                         type="text"
// //                         name="zipcode"
// //                         value={formData.zipcode || autoFillData.zipcode}
// //                         onChange={handleInputChange}
// //                         onKeyDown={handleKeyDown}
// //                         placeholder="Zipcode"
// //                     />
// //                     <div className="savebtn" onClick={handleSave}>
// //                         {isEditing ? 'Update Address' : 'Save Address'}
// //                     </div>
// //                 </div>
// //             )}
// //         </div>
// //     );
// // };

// // export default AUserAddress;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './UserAddress.css';
// import { useParams, Link } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const AUserAddress = () => {
//     const [show, setShow] = useState(false);
//     const [addresses, setAddresses] = useState([]);
//     const [formData, setFormData] = useState({
//         name: '',
//         address: '',
//         city: '',
//         country: 'India',
//         state: '',
//         zipcode: '',
//     });
//     const [isEditing, setIsEditing] = useState(false);
//     const [editAddressId, setEditAddressId] = useState(null);

//     const { pk, name } = useParams();

//     useEffect(() => {
//         const fetchAddresses = async () => {
//             try {
//                 const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
//                 setAddresses(response.data);
//             } catch (error) {
//                 console.error('Error fetching addresses:', error);
//             }
//         };

//         fetchAddresses();
//     }, [pk]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         if (name === 'zipcode' && value.length > 6) return;

//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             [name]: value,
//         }));
//     };

//     const handleKeyDown = async (e) => {
//         if (e.key === 'Enter' && e.target.name === 'address') {
//             const address = formData.address.trim();
//             if (address) {
//                 try {
//                     const response = await axios.get(`https://terracharms.com/address_suggestion/`, {
//                         params: { address }, // Pass the address as a query parameter
//                     });
//                     const suggestions = response.data.suggestions;

//                     if (suggestions) {
//                         const { city, state, zipcode, country } = suggestions;
//                         setFormData((prevData) => ({
//                             ...prevData,
//                             city,
//                             state,
//                             zipcode,
//                             country: country || 'India',
//                         }));
//                         toast.success('Address suggestions auto-filled successfully');
//                     } else {
//                         toast.error('No suggestions found');
//                     }
//                 } catch (error) {
//                     console.error('Error fetching address suggestions:', error);
//                     toast.error('Failed to fetch address suggestions');
//                 }
//             } else {
//                 toast.error('Please enter a valid address');
//             }
//         } else if (e.key === 'Enter') {
//             // Navigate to the next field on Enter key press
//             const currentIndex = ['name', 'address', 'city', 'state', 'zipcode'].indexOf(e.target.name);
//             const nextIndex = currentIndex + 1;
//             const nextField = document.querySelector(`[name=${['name', 'address', 'city', 'state', 'zipcode'][nextIndex]}]`);
//             if (nextField) {
//                 nextField.focus();
//             }
//         }
//     };

//     const handleSave = async () => {
//         try {
//             let response;
//             if (isEditing) {
//                 response = await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
//                 toast.success('Address updated successfully');
//             } else {
//                 response = await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
//                 toast.success(response.data.msg || 'Address added successfully');
//             }
//             setShow(false);
//             setIsEditing(false);
//             setEditAddressId(null);
//             setFormData({
//                 name: '',
//                 address: '',
//                 city: '',
//                 country: 'India',
//                 state: '',
//                 zipcode: '',
//             });
//             const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
//             setAddresses(addressResponse.data);
//         } catch (error) {
//             console.error('Error saving address:', error);
//             toast.error('Failed to save address');
//         }
//     };

    // const handleEdit = (address) => {
    //     setFormData(address);
    //     setIsEditing(true);
    //     setEditAddressId(address.id);
    //     setShow(true);
    // };

    // const handleDelete = async (id) => {
    //     try {
    //         await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
    //         toast.success('Address deleted successfully');
    //         const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
    //         setAddresses(addressResponse.data);
    //     } catch (error) {
    //         console.error('Error deleting address:', error);
    //         toast.error('Failed to delete address');
    //     }
    // };

//     return (
//         <div className="useraddress">
//             <ToastContainer />
//             <div className='button-header'>
//                 <div className="content-header">
//                     <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white ml-6 mt-4">
//                         Back To Profile
//                     </Link>
//                 </div>
//                 <div className="content-header">
//                     <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white mr-6 mt-4">
//                         Back To Home
//                     </Link>
//                 </div>
//             </div>

//             {!show && <h1 className="mainhead1">Your Address</h1>}
//             {!show && (
//                 <div className="addressin">
//                     {addresses.map((item) => (
//                         <div className="address" key={item.id}>
//                             <span>Name: {item.name}</span>
//                             <span>Address: {item.address}</span>
//                             <span>State: {item.state}</span>
//                             <span>City: {item.city}</span>
//                             <span>Zipcode: {item.zipcode}</span>
//                             <span>Country: {item.country}</span>
                            // <div className="editbtn" onClick={() => handleEdit(item)}>Edit</div>
                            // <div className="delbtn" onClick={() => handleDelete(item.id)}>Delete</div>
//                         </div>
//                     ))}
//                 </div>
//             )}

//             {!show && (
//                 <div className="addnewbtn" onClick={() => setShow(true)}>
//                     Add New
//                 </div>
//             )}

//             {show && (
//                 <div className="addnew">
//                     <h1>{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
//                     <div className="form">
//                         <div className="form-group">
//                             <label htmlFor="name">Name</label>
//                             <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="address">Address</label>
//                             <input type="text" name="address" value={formData.address} onChange={handleInputChange} onKeyDown={handleKeyDown} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="city">City</label>
//                             <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="state">State</label>
//                             <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="zipcode">Zip Code</label>
//                             <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="country">Country</label>
//                             <input type="text" name="country" value="India" readOnly />
//                         </div>
//                         <button onClick={handleSave} className="btn btn-primary">{isEditing ? 'Update' : 'Save'}</button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default AUserAddress;



import React, { useState, useEffect , useRef } from 'react';
import axios from 'axios';
import './UserAddress.css';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AUserAddress = () => {
    const [show, setShow] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        city: '',
        country: 'India',
        state: '',
        zipcode: '',
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editAddressId, setEditAddressId] = useState(null);
    const [suggestions, setSuggestions] = useState([]); // For address suggestions

    const { pk, name } = useParams();

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [pk]);
    const suggestionsRef = useRef(null);
        // Close suggestions when clicking outside
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                    setSuggestions([]); // Clear suggestions if clicked outside
                }
            };
    
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'zipcode' && value.length > 6) return;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // Fetch suggestions when the address field is updated
        if (name === 'address' && value.length > 2) {
            fetchAddressSuggestions(value);
        }
    };

    const fetchAddressSuggestions = async (query) => {
        try {
            const response = await axios.get(`https://terracharms.com/address_suggestion/`, {
                params: { address: query },
            });
            setSuggestions(response.data.suggestions || []);
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setFormData({
            ...formData,
            address: suggestion.city + ', ' + suggestion.state,
            city: suggestion.city,
            state: suggestion.state,
            country: suggestion.country,
            zipcode: suggestion.zipcode,
        });
        setSuggestions([]); // Clear suggestions after selection
    };
    const confirmAction = async (message) => {
        return new Promise((resolve) => {
            const toastId = toast.info(
                <div>
                    <p>{message}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                        <button
                            onClick={() => {
                                toast.dismiss(toastId); // Close the toast
                                resolve(true); // Confirm action
                            }}
                            style={{
                                padding: '5px 15px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Yes
                        </button>
                        <button
                            onClick={() => {
                                toast.dismiss(toastId); // Close the toast
                                resolve(false); // Cancel action
                            }}
                            style={{
                                padding: '5px 15px',
                                backgroundColor: '#f44336',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>,
                {
                    position: 'top-center',
                    autoClose: false, // Prevent auto-closing
                    closeOnClick: false, // Ensure buttons are used for action
                    draggable: false, // Prevent dragging
                    toastId: 'confirmation', // Unique ID to avoid duplicate toasts
                }
            );
        });
    };
    
    const handleSave = async () => {
        try {
            const confirmed = await confirmAction('Do you want to save this address?');
            if (!confirmed) return;
    
            let response;
            if (isEditing) {
                response = await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
                toast.success('Address updated successfully');
            } else {
                response = await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
                toast.success(response.data.msg || 'Address added successfully');
            }
    
            setShow(false);
            setIsEditing(false);
            setEditAddressId(null);
            setFormData({
                name: '',
                address: '',
                city: '',
                country: 'India',
                state: '',
                zipcode: '',
            });
    
            const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
            setAddresses(addressResponse.data);
        } catch (error) {
            console.error('Error saving address:', error);
            toast.error('Failed to save address');
        }
    };
    
    const handleEdit = (address) => {
        setFormData(address);
        setIsEditing(true);
        setEditAddressId(address.id);
        setShow(true);
    };

    const handleDelete = async (id) => {
        try {
            const confirmed = await confirmAction('Do you want to delete this address?');
            await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
            toast.success('Address deleted successfully');
            const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
            setAddresses(addressResponse.data);
        } catch (error) {
            console.error('Error deleting address:', error);
            toast.error('Failed to delete address');
        }
    };

    return (
        <div className="useraddress">
            <ToastContainer />
            <div className='button-header'>
                <div className="content-header">
                    <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white ml-6 mt-4">
                        Back To Profile
                    </Link>
                </div>
                <div className="content-header">
                    <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white mr-6 mt-4">
                        Back To Home
                    </Link>
                </div>
            </div>

            {!show && <h1 className="mainhead1">Your Address</h1>}
            {!show && (
                <div className="addressin">
                    {addresses.map((item, index) => (
                        <div className="address" key={index}>
                            <span className='mt-1'>Name: {item.name}</span>
                            <span>Address: {item.address}</span>
                            <span>State: {item.state}</span>
                            <span>City: {item.city}</span>
                            <span>Zipcode: {item.zipcode}</span>
                            <span>Country: {item.country}</span>
                            <div className="editbtn" onClick={() => handleEdit(item)}>Edit</div> 
                            <div className="delbtn" onClick={() => handleDelete(item.id)}>Delete</div>
                        </div>
                    ))}
                </div>
            )}

            {!show && (
                <div className="addnewbtn" onClick={() => setShow(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
            )}

            {show && (
                <div className="addnew">
                    <h1 className="mainhead1">{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
                    <div className="form">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                            {suggestions.length > 0 && (
                                <ul className="suggestions-dropdown">
                                    {suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {suggestion.city}, {suggestion.state} ({suggestion.zipcode})
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">State</label>
                            <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zipcode">Zip Code</label>
                            <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <input type="text" name="country" value="India" readOnly />
                        </div>
                        <button onClick={handleSave} className="btn btn-primary">{isEditing ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AUserAddress;