import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate , useLocation} from 'react-router-dom';
import axios from 'axios';
import ProductCard from './ProductCards';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon, MinusIcon, PlusIcon, Bars3Icon } from '@heroicons/react/24/outline';
import Navbar from '../../../features/navbar/Navbar';
import Footer from "../../../features/footer/Footer";
import "./AllProducts.css";

const sortOptions = [
 
  { name: 'Price: Low to High', value: 'lhprice' },
  { name: 'Price: High to Low', value: 'hlprice' },
];

const filters = [
  {
    id: "color",
    name: "COLOR",
    options: [
      { value: "White", label: "White" },
      { value: "Beige", label: "Beige" },
      { value: "Red", label: "Red" },
      { value: "Black", label: "Black" },
      { value: "Brown", label: "Brown" },
      { value: "Green", label: "Green" },
      { value: "Purple", label: "Purple" },
    ],
  },
  {
    id: "size",
    name: "SIZE",
    options: [
      { value: "Extra Large", label: "Extra Large" },
      { value: "Large", label: "Large" },
      { value: "Small", label: "Small" },
      { value: "Medium", label: "Medium" },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AllProducts = () => {
  const [isMobileSortOpen, setIsMobileSortOpen] = useState(false);
const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [filterState, setFilterState] = useState({
    sort: '',
    color: '',
    size: ''
  });

  // Define onSearch function
  // const handleSearch = (search) => {
  //   setsearch(search);
  // };
    const { pk,name } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search).get("query");
    const search1 = new URLSearchParams(location.search).get("search");
    // useEffect(() => {
    //   const fetchProductsData = async () => {
    //     try {
    //       const baseUrl = `https://terracharms.com/`;
    //       let url = `${baseUrl}user_search_get/${pk}/`; // Default URL
    //       let params = { search: search || '' };
    //       let response;
    
    //       // If search term exists and is not empty, use POST request to search for products
    //       if (search && search.trim() !== "") {
    //         url = `${baseUrl}user_search_get/${pk}/`; // Use POST for search queries
    //         response = await axios.get(url, { search: search });
    //         navigate(`/user/allproducts/${pk}/${name}?search=${search}`);
    //       } 
    //       else {
    //         // Handle filtering when no search term is provided
            
    //           // Handle filtering when no search term is provided
    //           if (filterState.sort === "lhprice") {
    //             url = `${baseUrl}recentsearched_lh/${pk}/`;
    //           } else if (filterState.sort === "hlprice") {
    //             url = `${baseUrl}recentsearched_hl/${pk}/`;
    //           } 
    //            else if (filterState.color) {
    //             url = `${baseUrl}recentsearched_colour/${pk}/`;
    //             params = { color: filterState.color };
    //           } else if (filterState.size) {
    //             url = `${baseUrl}recentsearched_size/${pk}/`;
    //             params = { size: filterState.size };
    //           }
    //        // Fetch the filtered products
    //       //  response = await axios.get(url, { params });
    //        response = await axios.get(url, {params: { search: search || search }});
    //         console.log(response.data)
    //         }
    //       // Set products to the response data
    //       setProducts(response.data);
    
    //     } catch (error) {
    //       // Log a more descriptive error message
    //       console.error("Error fetching products:", error.response?.data || error.message);
    //     } finally {
    //       // Set loading to false once the request completes, even if it fails
    //       setLoading(false);
    //     }
    //   };
    
    //   // Trigger the function to fetch products when dependencies change
    //   fetchProductsData();
    // }, [search, pk,filterState, setProducts]);

// useEffect(() => {
//   const fetchProductsData = async () => {
//     try {
//       const baseUrl = `https://terracharms.com/`;
//       let url = `${baseUrl}user_search_get/${pk}/`; // Default URL
//       let params = {};
     

//       if (search && search.trim() !== "") {
//         // Handle search query
//         params = { search: search };
//         url = `${baseUrl}user_search_get/${pk}/`;
//         await axios.get(url, { params }); // Send search request
//         navigate(`/user/allproducts/${pk}/${name}?search=${search}`);
//       } else {
//         if (filterState.color || filterState.size) {
//           url = `${baseUrl}filtering/`; // Use filtering API for filters
//           const params = {
//             ...(filterState.color && { color: filterState.color }), // Add color if selected
//             ...(filterState.size && { size: filterState.size }), // Add size if selected
//             query: searchQuery || "", // Include the search query (if any)
//           };
//           response = await axios.get(url, { params });
//         } 
//         // Handle filters when no search term is provided
//         // if (filterState.sort === "lhprice") {
//         //   url = `${baseUrl}recentsearched_lh/${pk}/`;
//         // } else if (filterState.sort === "hlprice") {
//         //   url = `${baseUrl}recentsearched_hl/${pk}/`;
//         // }
//         //  else if (filterState.color) {
//         //   url = `${baseUrl}recentsearched_colour/${pk}/`;
//         //   params = { color: filterState.color };
//         // } 
//         // else if (filterState.size) {
//         //   url = `${baseUrl}recentsearched_size/${pk}/`;
//         //   params = { size: filterState.size };
//         // }

//         // Fetch filtered products
//         const response = await axios.get(url, {
//           params: {
//             query: searchQuery || query || undefined, // Use searchQuery, fallback to query if not present
//             search: search || search1 || undefined,  // Use search, fallback to search1 if not present
//           },
//         });
        
//         // Process response
//         setProducts(response.data);
//         console.log("Fetched products:", response.data);
//     catch (error) {
//       console.error("Error fetching products:", error.response?.data || error.message);
//     } finally {
//       setLoading(false); // Ensure loading spinner is hidden
//     }
//   };

//   // Trigger the function when dependencies change
//   fetchProductsData();
// }, [search,searchQuery, pk, filterState, setProducts, navigate,search1]);
    

useEffect(() => {
  const fetchProductsData = async () => {
    try {
      const baseUrl = `https://terracharms.com/`;
      let url = `${baseUrl}user_search_get/${pk}/`; // Default URL
      let params = {};

      if (search && search.trim() !== "") {
        // Handle search query
        params = { search };
        url = `${baseUrl}user_search_get/${pk}/`;
        const response = await axios.get(url, { params }); // Send search request
        navigate(`/user/allproducts/${pk}/${name}?search=${search}`);
        setProducts(response.data); // Update products with search results
        console.log("Fetched products (search):", response.data);
      } else {
        if (filterState.color || filterState.size) {
          // Handle filtering
          url = `${baseUrl}filtering/`; // Use filtering API
          params = {
            ...(filterState.color && { color: filterState.color }), // Add color if selected
            ...(filterState.size && { size: filterState.size }),   // Add size if selected
            query: searchQuery || "", // Include search query (if any)
          };
        } else {
          // Handle other filters or queries
          params = {
            query: searchQuery || query || undefined, // Fallback logic for query
            search: search || search1 || undefined,  // Fallback logic for search
          };
        }

        // Fetch filtered products
        const response = await axios.get(url, { params });
        setProducts(response.data); // Update products with filtered results
        console.log("Fetched products (filters):", response.data);
      }
    } catch (error) {
      console.error("Error fetching products:", error.response?.data || error.message);
    } finally {
      setLoading(false); // Ensure loading spinner is hidden
    }
  };

  fetchProductsData(); // Call the function
}, [search, searchQuery, pk, name, filterState, query, search1, setProducts]);

  const filteredProducts = products.filter(product =>
    product.product_name.toLowerCase().includes(search.toLowerCase())
  );

  // Handle filter changes
  const handleSortChange = (value) => {
    setFilterState({
      sort: value,
      color: '',
      size: ''
    });
  };

  const handleColourChange = (value) => {
    setFilterState({
      sort: '',
      color: value,
      size: ''
    });
  };

  const handleSizeChange = (value) => {
    setFilterState({
      sort: '',
      color: '',
      size: value
    });
  };

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }


  return (
    <>
    <div className="bg-white">
      <Navbar onSearch={setsearch} />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Desktop Version */}
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
        <h1 className="hidden lg:block text-4xl font-bold tracking-tight text-gray-900">Filters</h1>

<div className="hidden lg:flex items-center"> {/* Hidden on mobile */}
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
        Sort
        <ChevronDownIcon
          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {sortOptions.map((option) => (
            <Menu.Item key={option.value}>
              {({ active }) => (
                <button
                  onClick={() => handleSortChange(option.value)}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                  )}
                >
                  {option.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
</div>

{/* Mobile Sort Button */}
<div className="lg:hidden">
              <button
                className="text-gray-500 hover:text-gray-700 mt-6"
                onClick={() => setIsSidebarOpen(true)}
              >
                <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                <span className="ml-2">Filter</span>
              </button>
            </div>
<div className="lg:hidden flex items-center"> {/* Visible on mobile */}


  <button
    className="text-gray-500 hover:text-gray-700 "
    onClick={() => setIsMobileSortOpen(true)} // Function to open mobile sort options
  >  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 mb-2" aria-hidden="true" />
    
    Sort
  
  </button>
</div>
</div>

{/* Mobile Sort Options Modal */}
<Dialog
as="div"
className="relative z-40 lg:hidden"
open={isMobileSortOpen} // State to manage mobile sort options visibility
onClose={() => setIsMobileSortOpen(false)} // Function to close mobile sort options
>
<div className="fixed inset-0 bg-black bg-opacity-25" />
<div className="fixed inset-0 flex z-40">
  <Dialog.Panel className="relative ml-auto max-w-xs w-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
    <div className="px-4 flex items-center justify-between">
      <h2 className="text-lg font-medium text-gray-900">Sort Options</h2>
      <button
        type="button"
        className="text-gray-400 hover:text-gray-500"
        onClick={() => setIsMobileSortOpen(false)} // Function to close mobile sort options
      >
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>

    <div className="py-1">
      {sortOptions.map((option) => (
        <button
          key={option.value}
          onClick={() => {
            handleSortChange(option.value);
            setIsMobileSortOpen(false); // Close modal after selection
          }}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {option.name}
        </button>
      ))}
    </div>
  </Dialog.Panel>
</div>
</Dialog>


        <section aria-labelledby="products-heading" className="pt-6 pb-24">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            {/* Button to open the sidebar on mobile */}
          

            {/* Sidebar for mobile view */}
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              open={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
            >
              <div className="fixed inset-0 bg-black bg-opacity-25 p-2" />

              <div className="fixed inset-0 flex z-40">
                <Dialog.Panel className="relative ml-auto max-w-xs w-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                  <div className="px-4 flex items-center justify-between">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Sidebar form */}
                  <form className="mt-4 ml-5">
                    {filters.map((section) => (
                      <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-4">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      type="radio"
                                      value={option.value}
                                      onChange={() => {
                                        if (section.id === "color") {
                                          handleColourChange(option.value);
                                        } else if (section.id === "size") {
                                          handleSizeChange(option.value);
                                        }
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </div>
            </Dialog>

            {/* Desktop form */}
            <form className="hidden lg:block">
              {filters.map((section) => (
                <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                  {({ open }) => (
                    <>
                      <h3 className="-my-3 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-900">{section.name}</span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <PlusIcon className="h-5 w-5" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6">
                        <div className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <input
                                id={`filter-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                type="radio"
                                value={option.value}
                                onChange={() => {
                                  if (section.id === "color") {
                                    handleColourChange(option.value);
                                  } else if (section.id === "size") {
                                    handleSizeChange(option.value);
                                  }
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </form>

            {/* Product list */}
            <div className="lg:col-span-3">
              <div className="product-list">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 gap-y-8">
                  {filteredProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
    <Footer />
  </>
  );
};

export default AllProducts;
