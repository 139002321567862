import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams , Link} from 'react-router-dom';

const SAccountSettings = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Mobile_no: '',
    email: '',
  });
  const { pk } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/seller_profile/${pk}/`);
        const data = response.data;
        setFormData({
          Name: data.Name || '',
          Mobile_no: data.Mobile_no || '',
          email: data.email || '',
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [pk]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`https://terracharms.com/edit_seller_profile/${pk}/`, formData);
      console.log('Edit Response:', response.data);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div style={styles.accountSettings}>
                          <div className="content-header mt-10 ml-6">
        <Link to={`/seller/Profile/${pk}/`} className="btn btn-dark text-white">
          Back To Profile
        </Link></div>
      <h1 style={styles.mainHead1}>Personal Information</h1>

      <div style={styles.form}>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor='first_name'>
            Your Name <span>*</span>
            </label>
            <input
              type='text'
              name='Name'
              id='Name'
              value={formData.Name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor='Mobile_no'>Phone/Mobile*</label>
            <input
              type='text'
              name='Mobile_no'
              id='Mobile_no'
              value={formData.Mobile_no}
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor='email'>
              Email <span>*</span>
            </label>
            <input
              type='email'
              name='email'
              id='email'
              value={formData.email}
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>

          <button type='submit' style={styles.button}>
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  accountSettings: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#f4f4f4',
  },
  mainHead1: {
    fontSize: '2rem',
    marginBottom: '30px',
    color: '#333',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  form: {
    backgroundColor: '#ffffff',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '600px',
  },
  formGroup: {
    marginBottom: '20px',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '15px',
    fontSize: '1.2rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease',
    height: 'auto',
  },
  button: {
    width: '100%',
    marginTop: '10px',
    padding: '20px',
    backgroundColor: '#28a745',
    border: 'none',
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default SAccountSettings;
