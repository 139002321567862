import React, { useState, useEffect } from 'react';
import './YourOrders.css';
import axios from 'axios';
import Navbar from '../../../features/navbar/Navbar';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const YourOrders = (product) => {
    const { pk, name, product_id } = useParams();
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const fetchSearchResults = async () => {
                try {
                    const response = await axios.get(
                        `https://terracharms.com/user_search_get/${pk}/`,
                        { params: { search: searchTerm } }
                    );
                    navigate(`/user/allproducts/${pk}/${name}/`);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            };
            fetchSearchResults();
        }
    }, [searchTerm, pk, navigate]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const month = monthNames[monthIndex];
        return `${day} ${month} ${year}`;
    };

    useEffect(() => {
        axios.get(`https://terracharms.com/your_orders/${pk}/`)
            .then(response => {
                setOrders(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            });
    }, [pk]);

    const handleConfirmAction = (order_item_id, actionType) => {
        const action = actionType === 'cancel' ? 'cancel your order' : 'return your order';

        toast.info(
            <div>
                <p>Are you sure you want to {action}?</p>
                <button
                    onClick={() => actionType === 'cancel' ? handleCancelOrder(order_item_id) : handleReturnOrder(order_item_id)}
                    className="btn btn-primary"
                >
                    Yes
                </button>
            </div>,
            { autoClose: false }
        );
    };

    const handleCancelOrder = async (order_item_id) => {
        toast.dismiss();  // Close the confirmation toast
        try {
            const response = await axios.delete(`https://terracharms.com/cancel_user_order/${pk}/${order_item_id}/`);
            toast.success(response.data.msg);
            setOrders(orders.filter(order => order.order_item_id !== order_item_id));
        } catch (error) {
            console.error('Error canceling order:', error);
            
            // Check if error response exists and get the message from it
            const errorMsg = error.response && error.response.data ? error.response.data.msg : 'There was an error canceling the order.';
            toast.error(errorMsg);
        }
    };
    

    const handleReturnOrder = async (order_item_id) => {
        toast.dismiss();  // Close the confirmation toast
        try {
            await axios.post(`https://terracharms.com/return_orders/${order_item_id}/`);
            toast.success('Order returned successfully.');
        } catch (error) {
            console.error('Error returning order:', error);
            toast.error('There was an error processing your return.');
        }
    };

    return (
        <>
            <ToastContainer />
            <Navbar onSearch={setSearchTerm} />
            <div className='yourorders'>
                <div className='button-header'>
                    <div className="content-header">
                        <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white ml-6 mt-4">
                            Back To Profile
                        </Link>
                    </div>
                    <div className="content-header">
                        <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white mr-6 mt-4">
                            Back To Home
                        </Link>
                    </div>
                </div>
                <h1 className='mainhead1'>Your Orders</h1>
                <div className='orders-container'>
                    {orders.map((orderdata, index) => (
                        <div className='order-card' key={index}>
                            <h3>Order ID: {orderdata.order_id}</h3>
                            <div className='order-details'>
                                <div className='order-item'>
                                    <span>Order Date:</span>
                                    <span>{formatDate(orderdata.order_date)}</span>
                                </div>
                                <div className='order-item'>
                                    <span>Order Status:</span>
                                    <span>{orderdata.order_status}</span>
                                </div>
                                <Link to={`/user/productpage/${pk}/${orderdata.product_id}/${name}/`}>
                                    <div className='product-image-container'>
                                        <img src={`https://terracharms.com/${orderdata.image}`} alt="Product" className='product-image' />
                                    </div>
                                </Link>
                                <div className='order-item'>
                                    <span>Total:</span>
                                    <span>₹{orderdata.Total}</span>
                                </div>
                            </div>
                            <div className='button-container'>
                                <button
                                    className='action-btn invoice-btn'
                                    onClick={() => navigate(`/user/invoice/${pk}/${orderdata.order_item_id}/${name}/`)}
                                >
                                    View Invoice
                                </button>
                                <button
                                    className='action-btn return-btn bg-blue-900'
                                    onClick={() => handleConfirmAction(orderdata.order_item_id, 'return')}
                                >
                                    Return
                                </button>
                                <button
                                    className='action-btn cancel-btn'
                                    onClick={() => handleConfirmAction(orderdata.order_item_id, 'cancel')}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default YourOrders;
