import React from 'react'
import { Link,useParams } from 'react-router-dom'
import './SUserSidebar.css'
const SUserSidebar = ({ activepage }) => {  
  const {pk} = useParams();
  return (
    <div className="user-profile">
    <div className="profile-header">
    <h2 className='font-semibold '>Welcome, Seller!</h2>
    <img 
    src="https://as1.ftcdn.net/v2/jpg/04/25/86/36/1000_F_425863611_5Peju5bLE8DT3XWN1iZjpjyn0UAvNZbJ.jpg" 
    alt="Profile" 
    className="profile-pic" 
  />
     
    
    </div>

    <div className="profile-sections">
      <div className="profile-section">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <Link
          to={`/seller/AccountSettings/${pk}/`}
          className='stylenone'

        >
          <h3 className='font-semibold'>Account Settings</h3>
          <p>Manage your account settings and preferences.</p>
        </Link>
      </div>

      <div className="profile-section">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
               <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
             </svg>
             <Link
          to={`/seller/orders/${pk}/`}
           className='stylenone'>
           <h3 className='font-semibold'>Your Orders</h3>
          <p>View your recent orders and their status.</p>
        </Link>
      </div>

      <div className="profile-section">
 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />               </svg>
              <Link
          to={`/seller/address/${pk}/`}
           className='stylenone'>
           <h3 className='font-semibold'>Address</h3>
          <p>Manage your saved addresses.</p>
        </Link>
      </div>

      <div className="profile-section">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
             <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
           </svg>
           <Link
          to={`/seller/LegalNotice/`} className='stylenone'>
<h3 className='font-semibold'>Legal Notice</h3>
          <p>View the legal terms and notices.</p>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default SUserSidebar