import './ProductCard.css';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";

const ProductCard1 = ({ product }) => {
  const { id } = useParams();  // Get the pk from the route parameters
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/get_all_sub_products/${id}/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [id]);

  return (
    <>
      {/* Ensure no underline with inline style */}
      <Link
        to={`/user/productpage1/${product.id}/`}
        style={{ textDecoration: 'none' }} // Remove underline from the link
        className="no-underline"
      >
        <div className="shadow-lg bg-white rounded-lg p-4 hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer">
          <div className="group relative bg-white">
            {/* Product Image */}
            <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
              <img
                alt={product.product_name}
                src={`https://terracharms.com${product.product_image}/`}
                className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
              />
            </div>

            {/* Product Details */}
            <div className="mt-4 text-center">
              <h3 className="text-truncate text-sm sm:text-base font-semibold text-gray-800" style={{ textDecoration: 'none' }}>
                {product.product_name}
              </h3>

              {/* Product Prices */}
              <div className="price-container mt-2">
                <p className="text-l text-teal-600" style={{ textDecoration: 'none' }}>
                  ₹{product.discount_price.toFixed(2)}{" "}
                  <span className="line-through ml-2 text-red-400" style={{ textDecoration: 'line-through' }}>
                    ₹{product.actual_price.toFixed(2)}
                  </span>
                </p>

                {/* Discount Badge */}
                {product.discount_price && product.actual_price && (
                  <span className="discount-box text-sm ml-2 text-white bg-green-700 px-2 py-1 rounded-lg" style={{ textDecoration: 'none' }}>
                    {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                  </span>
                )}
              </div>

              {/* Product Size */}
              <div className="text-gray-700 text-sm mt-2" style={{ textDecoration: 'none' }}>
                Size: {product.size}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductCard1;
