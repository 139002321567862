import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Approve2 from "./Approve2"; // Adjust import as needed

const Approve = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("All category");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  
  const { pk } = useParams(); // Assuming you pass sellerId as a URL parameter

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://terracharms.com/get_category/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    fetchAllProducts();
  }, [pk]);

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get(`https://terracharms.com/get_approved_product/${pk}/`);
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching the products!", error);
      setProducts([]); // Ensure products is always an array
    }
  };

  const fetchProductsByCategory = async (selectedCategory) => {
    console.log("Selected Category:", selectedCategory); // Log selected category to console
    try {
      const response = await axios.get(`https://terracharms.com/categoryproductsadmin/${pk}/`, {
        params: { Category_name: selectedCategory },
      });
      console.log("Response data:", response.data); // Log response data to console
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching products by category!", error);
      setProducts([]); // Ensure products is always an array
    }
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    if (selectedCategory === "All category") {
      fetchAllProducts();
    } else {
      fetchProductsByCategory(selectedCategory);
    }
  };

  const handleSearch = async (searchTerm) => {
    setSearchTerm(searchTerm);
    try {
      const response = await axios.get(`https://terracharms.com/search_admin/${pk}/`, {
        params: { query: searchTerm },
      });
      setProducts(response.data);
    } catch (error) {
      console.error("There was an error fetching the search results!", error);
      setProducts([]); // Ensure products is always an array
    }
  };
  
  const handleEditClick = (productId) => {
    navigate(`/admin/product/edit/${pk}/${productId}`);
  };

  return (
    <section className="content-main">
      <div className="lg:col-span-3">
        <div className="product-list">          
          <div className="row">
            {Array.isArray(products) && products.length > 0 ? (
              products.map((product, index) => (
                <Approve2
                  key={product.id}
                  product={product}
                  index={index}
                  handleEditClick={handleEditClick}
                />
              ))
            ) : (
              <p>No products found.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Approve;
