import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer2 from "../../../features/footer/Footer2";
import Navbar2 from "../../../features/navbar/Navbar2";
import axios from "axios";
import "./ProductPage.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from "react-slick";

function ProductPage1() {
  const { id} = useParams();
  const [productdata, setProductdata] = useState({});
  const [activeMedia, setActiveMedia] = useState({}); // Changed from activeImg to activeMedia
  const [imageset, setImageset] = useState([]);
  const [count, setCount] = useState(1);
  const [searchquery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [cartAdded, setCartAdded] = useState(false);
// let toastCount = 0;
  // const showSignUpToast = () => {
  //   // if (toastCount < 2) {  // Set the limit here (e.g., 2 times)
  //     toast.info("Please sign up first to add products to your cart.", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       closeButton: false,
  //     });
  //     // toastCount++;  // Increment the counter
  //   }
  // // };
  // const navigate = useNavigate();
  const showSignUpToast = () => {
  
    // Show the first toast asking the user to sign up
    toast.info(
      <>
        <div>Please sign up first to add products to your cart.</div>
        <button
          onClick={() => {
            navigate('/signup'); // Redirect to the signup page
          }}
          style={{
            marginTop: '10px',
            padding: '8px 16px',
            backgroundColor: '#4840b1',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Sign Up
        </button>
      </>,
      {
        position: "top-right",
        autoClose: 3000, // This will automatically close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    if (searchquery.trim() !== '') {
      const fetchSearchResults = async (query) => {
        try {
          await axios.get(
            `https://terracharms.com/search_api/`,
            {
              params: { query: query }
            }
          );
          navigate(`/user/allproducts1/`);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };
      fetchSearchResults();
    }
  }, [searchquery, navigate]);

  // useEffect(() => { 
  //   const getProductDataById = async () => {
  //     try {
  //       let response = await axios.get(`https://terracharms.com/get_product/${id}/`);
  //       if (!response.data) {
  //         throw new Error("Failed to fetch product data");
  //       }
  //       let product = response.data;
  //       setProductdata(product);
  //       setActiveMedia({ id: 1, type: 'image', url: product.product_image });
  
  //       setImageset([
  //         { id: 1, type: 'image', url: product.product_image },
  //         { id: 2, type: 'image', url: product.product_image2 },
  //         { id: 3, type: 'image', url: product.product_image3 },
  //         { id: 4, type: 'image', url: product.product_image4 },
  //         { 
  //           id: 5, 
  //           type: product.product_video.includes('youtube') ? 'youtube' : 'video', 
  //           url: product.product_video 
  //         }, // Check if it's a YouTube URL
  //       ]);
  //     } catch (error) {
  //       console.error("Error fetching product data:", error);
  //       toast.error("Failed to fetch product details");
  //     }
  //   };
  //   getProductDataById();
  //   window.scroll(0, 0);
  // }, [id]);
  useEffect(() => { 
    const getProductDataById = async () => {
      try {
        let response = await axios.get(`https://terracharms.com/get_product/${id}/`);
        if (!response.data) {
          throw new Error("Failed to fetch product data");
        }
        let product = response.data;
        setProductdata(product);
        setActiveMedia({ id: 1, type: 'image', url: product.product_image });

        setImageset([
          { id: 1, type: 'image', url: product.product_image },
          { id: 2, type: 'image', url: product.product_image2 },
          { id: 3, type: 'image', url: product.product_image3 },
          { id: 4, type: 'image', url: product.product_image4 },
          { 
            id: 5, 
            type: product.product_video && product.product_video.includes('youtube') 
              ? 'youtube' 
              : 'video', 
            url: product.product_video || '' // Provide a default empty string for safety
          },
        ]);
      } catch (error) {
        console.error("Error fetching product data:", error);
        toast.error("Failed to fetch product details");
      }
    };

    getProductDataById();
    window.scroll(0, 0);
  }, [id]);

  const settings1 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  const mobileSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleQuantityChange = (amount) => {
    if (count + amount > 0) {
      setCount(count + amount);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: productdata.product_name,
        url: `https://terracharms.com/get_product/${id}/`
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const discountPercentage = productdata.actual_price && productdata.discount_price
    ? ((productdata.actual_price - productdata.discount_price) / productdata.actual_price * 100).toFixed(2)
    : null;

    const handleCartButtonClick = () => {
      navigate(`/signup`); 
    };
  const handleImageClick = (item) => {
    if (item.type === 'youtube') {
      // If the clicked item is a YouTube video, set the active media to show the video thumbnail
      setActiveMedia({ id: item.id, type: 'youtube', url: item.url });
    } else {
      setActiveMedia(item); // Set active media (image or video)
    }
  };

  const getYoutubeVideoId = (url) => {
    const regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  
  return (
    <>
     <Navbar2 onSearch={setSearchQuery} />
      <div className="mt-6"></div>
      <div className="productpage"> 
  <div className="pc1">
  <div className="c11"> 
  <div className="imgset">
    {imageset.map((item) => (
      <div
        className="imgsmall h-full w-full"
        key={item.id}
        onClick={() => handleImageClick(item)} // No changes here
      >
        {item.type === 'image' ? (
          <img
            src={`https://terracharms.com/${item.url}`}
            alt={`Image ${item.id}`}
            className={activeMedia.id === item.id ? "active" : ""}
          />
        ) : item.type === 'youtube' ? (
          <img
            src={`https://img.youtube.com/vi/${getYoutubeVideoId(item.url)}/0.jpg`} // Displaying YouTube thumbnail
            alt="YouTube Video"
            className={activeMedia.id === item.id ? "active" : ""}
          />
        ) : (
          <video className="w-full h-full">
            <source
              src={`${item.url}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    ))}
  </div>

      <div className="imgbig">
        <div className="zoom-container">
          <div className=''>
            {activeMedia.type === 'video' ? (
              <video controls className="active-video">
                <source
                  src={`https://terracharms.com/${activeMedia.url}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : activeMedia.type === 'youtube' ? (
              <iframe
                width="100%"
                height="500px"
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(activeMedia.url)}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                src={`https://terracharms.com/${activeMedia.url}`}
                alt={`Image ${activeMedia.id}`}
                className="active-image"
              />
            )}
          </div>
          <button className="share-button" onClick={handleShare}>
            <i className="fas fa-share-alt"></i>
          </button>
        </div>
      </div>
    </div>
          <div className='c12'>
            <h5 className='text-2xl text-gray-900 font-semibo'>{productdata.product_name}</h5>
            <div className="price-container">
              <p className="price">
                ₹{(productdata.discount_price * count).toFixed(2)}
                <span className="actual-price">₹{(productdata.actual_price * count).toFixed(2)}</span>
              </p>
              {discountPercentage && (
                <span className="discount-box">
                  {discountPercentage}% OFF
                </span>
              )}
            </div>
            <div className="btncont">
  {/* ToastContainer renders the toasts */}
  <ToastContainer />
  {productdata.in_stock > 1 ? (
    <>
      <button onClick={showSignUpToast}>Add to Cart</button>
      <button onClick={showSignUpToast}>Buy Now</button>         
    </>
  ) : (
    <p className="out-of-stock-message">Out of Stock</p>
  )}
</div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
  <div>
    <h1 className="font-bold text-1xl text-gray-900">Description</h1>
    <div className="space-y-6">
      <p className="text-base text-gray-900 ml-2 break-words overflow-hidden">{productdata.description}</p>
    </div>
  </div>
  <div className="mt-10">
    <h1 className="font-bold text-gray-900">Highlights</h1>
    <div className="space-y-6">
      <p className="text-base ml-2 text-gray-900 break-words overflow-hidden">{productdata.Highlights}</p>
    </div>
  </div>
  <div className="mt-10">
    <h1 className="font-bold text-gray-900">Details</h1>
    <div className="space-y-6">
      <p className="text-gray-900 ml-2 text-base break-words overflow-hidden">{productdata.Details}</p>
    </div>
  </div>
</div>
                           <div class="trust-badges">
   <div class="badge">
     <img src="/images/secure.jpeg" alt="Secure Transaction" />
     <p>Safe Payment</p>
   </div>
   <div class="badge">
     <img src="/images/returnable.jpeg" alt="7-Day Replacement" />
     <p>7 Day Return</p>
   </div>
   <div class="badge">
     <img src="/images/war.png" alt="Manufacturer Warranty" />
     <p>Warranty Covered</p>
   </div>
 </div>

           <hr /> 
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default ProductPage1;
