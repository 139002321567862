import Seller from "../features/seller/components/Seller"
import Footer2 from "../features/footer/Footer2";
function SellerPageCopy(){
    return(
        <div>
         <Seller></Seller> 
         <Footer2></Footer2>
        </div>
    );

}

export default SellerPageCopy;