import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { increment, incrementAsync, selectCount } from '../authSlice';

export default function Login() {
  const count = useSelector(selectCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMobileNumberFilled, setIsMobileNumberFilled] = useState(false);
  const [userName, setUserName] = useState('');

  const checkLoginStatus = async () => {
    try {
      // Make the PATCH request to get the login status
      const response = await axios.patch('https://terracharms.com/login_otp/', {
        Mobile_no: mobileNumber,
      });
      return response.data; // Returns { pk, is_active, first_name }
    } catch (error) {
      console.error('Error checking login status:', error);
      return null;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isMobileNumberFilled) {
      setError('Please fill in your mobile number.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Check if the user is already logged in or needs to go through OTP verification
      const userData = await checkLoginStatus();

      if (userData) {
        const { pk, is_active, name } = userData; // Extract first_name from response
        console.log(userData.name);
        setUserName(name); // Set the user's first name

        if (is_active) {
          // User is already active, navigate to the front page
          navigate(`/front/${pk}/${name}/`);
        } else {
          // User is not active, navigate to OTP verification page
          navigate(`/otp/${pk}/${name}/`, { state: { name: name } }); // Pass the user's name
        }
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error || 'An error occurred during login.');
      } else {
        setError('An error occurred during login.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
    setIsMobileNumberFilled(event.target.value !== '');
  };

  return (
    <div className="mx-auto mt-12 bg-white max-w-2xl px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="h-16 w-16 ml-40"
          src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
          alt="StarkMart"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Log in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="mobileNumber" className="block text-sm font-medium leading-6 text-gray-900">
              Mobile Number
            </label>
            <div className="mt-2">
              <input
                type="number"
                id="mobileNumber"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                placeholder="Enter your mobile number"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={!isMobileNumberFilled || loading}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? 'Logging in...' : 'Log in'}
            </button>
          </div>

          {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <Link to="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Create an Account
          </Link>
        </p>
      </div>
    </div>
  );
}
