import React from 'react';
import "./footer.css";
import { Link, useParams } from "react-router-dom";

export default function App() {
  const { pk,name} = useParams();
  return (
    <footer className="footer bg-blue-900">
      <div className="footer-item">
        <img src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp" alt="StarkMart Logo" className="footer-logo" />
        <p className="text-3xl">The StarkMart</p>
      </div>
      
      <div className="footer-item">
        <h3 className="footer-heading">Visit Our Store</h3>
        <p>C 21 A GROUND FLOOR, DELHI, DELHI, 110064</p>
        <h3><i className="fas fa-envelope"></i> Mail</h3>
        <p><a href="mailto:starkmartindia@gmail.com">starkmartindia@gmail.com</a></p>
        <h3><i className="fas fa-phone"></i> Phone</h3>
        <p><a href="tel:9911175143">9911175143</a></p>
      </div>
   
      <div className="footer-item">
        <h3 className="footer-heading">Contact Us</h3>
        <p><a href="tel:9911175143">9911175143</a></p>
        <p><a href="tel:9911175143">93555 29916</a></p>
      </div>
     
      <div className="footer-item">
        <h3 className="footer-heading">Legal</h3>
        <ul className="footer-list">
          <Link to={`/footer/${pk}/${name}/`}><li className="footer-list-item">Terms & Conditions</li></Link>
          <Link to={`/footer/${pk}/${name}/`}><li className="footer-list-item">Privacy Policy</li></Link>
          <Link to={`/footer/${pk}/${name}/`}><li className="footer-list-item">Shipping & Payment Policy</li></Link>
        </ul>
      </div>
      
      <div className="footer-item">
        <h3 className="footer-heading">Social</h3>
        <ul className="footer-list">
          <Link to="/facebook"><li className="footer-list-item">Facebook</li></Link>
          <Link to="/twitter"><li className="footer-list-item">Twitter</li></Link>
          <Link to="/instagram"><li className="footer-list-item">Instagram</li></Link>
        </ul>
      </div>
    </footer>
  );
}
