import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate , Link} from "react-router-dom";

const EditProductMain = ({ product, pk }) => {
  const navigate = useNavigate();
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [formData, setFormData] = useState({
    product_name: "",
    discount_price: "",
    product_image: "",
    product_image2: "",
    product_image3: "",
    product_image4: "",
    product_video: "",
    description: "",
    height: "",
    weight: "",
    size: "",
    colour: "",
    in_stock: "",
    stock_keeping_unit: "",
    actual_price: "",
    sub_category_name: "",
    Details: "",
    Highlights: "",
    dilevery_charges: '',
  });

  useEffect(() => {
    if (product) {
      setFormData({
        product_name: product.product_name || "",
        discount_price: product.discount_price || "",
        product_image: product.product_image || "",
        product_image2: product.product_image2 || "",
        product_image3: product.product_image3 || "",
        product_image4: product.product_image4 || "",
        product_video: product.product_video || "",
        description: product.description || "",
        height: product.height || "",
        weight: product.weight || "",
        size: product.size || "",
        colour: product.colour || "",
        in_stock: product.in_stock || "",
        stock_keeping_unit: product.stock_keeping_unit || "",
        actual_price: product.actual_price || "",
        sub_category_name: product.sub_category_name || "",
        Details: product.Details || "",
        Highlights: product.Highlights || "",
        dilevery_charges:product.dilevery_charges || "" ,
      });
    }
  }, [product]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_sub_categories/');
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };

  const [imageInputs, setImageInputs] = useState([{ id: 1, file: null }, { id: 2, file: null }, { id: 3, file: null }, { id: 4, file: null }]);
  const [productVideoUrl, setProductVideoUrl] = useState(''); // State for video URL input
   const handleVideoUrlChange = (e) => {
     setProductVideoUrl(e.target.value); // Set the video URL from input
   };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        updatedFormData.append(key, formData[key]);
      }
    }

    // Append image files to FormData
    imageInputs.forEach((input, index) => {
      if (input.file) {
        updatedFormData.append(`product_image${index + 1}`, input.file);
      }
    });

    // Append product video URL to FormData
    if (productVideoUrl) {
      updatedFormData.append('product_video', productVideoUrl);
    }


    try {
      const response = await axios.patch(
        `https://terracharms.com/edit_admin_product/${pk}/${product.id}/`,
        updatedFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log("Edit Response:", response.data);
      alert("Product updated successfully");
      navigate(`/admin/products/${pk}`);
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product");
    }
  };

  return (
    <section className="content-main" style={{ maxWidth: "1200px" }}>
      <form onSubmit={handleSubmit}>
        <div className="content-header">
          <Link to={`/admin/products/${pk}`} className="btn btn-danger text-white">
              Go to products
            </Link>
          <h2 className="content-title">Update Product</h2>
          <div>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </div>

        <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="product_name"
                      name="product_name"
                      value={formData.product_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="actual_price" className="form-label">
                      Actual Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="actual_price"
                      name="actual_price"
                      value={formData.actual_price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="discount_price" className="form-label">
                      Discount Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="discount_price"
                      name="discount_price"
                      value={formData.discount_price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="in_stock" className="form-label">
                      In Stock
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="in_stock"
                      name="in_stock"
                      value={formData.in_stock}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="weight"
                      placeholder="Weight"
                      value={formData.weight}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                    <input
                      type="text"
                      name="height"
                      placeholder="Height"
                      value={formData.height}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                    <select
                      className="form-select mt-2"
                      name="size"
                      value={formData.size}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Size</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                      <option value="Extra Large">Extra Large</option>
                    </select>
                    <select
                      className="form-select mt-2"
                      name="colour"
                      value={formData.colour}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Colour</option>
                      <option value="Red">Red</option>
                      <option value="Green">Green</option>
                      <option value="Black">Black</option>
                      <option value="White">White</option>
                      <option value="Brown">Brown</option>
                      <option value="Purple">Purple</option>
                      <option value="Beige">Beige</option>
                    </select>
                    <input
                      type="text"
                      name="stock_keeping_unit"
                      placeholder="SKU"
                      value={formData.stock_keeping_unit}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Details</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="Details"
                      value={formData.Details}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Highlights</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="highlights"
                      value={formData.Highlights}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/* Dilevery_charges Input */}
      <div className="mb-4">
        <label className="form-label">Delivery Charges</label>
        <input
          className="form-control"
          type="number"
          name="dilevery_charges"
          placeholder="Enter dilevery_charges"
          value={formData.dilevery_charges}
          onChange={handleInputChange}
        />
      </div>
                    <div className="mb-4">
  <label className="form-label">Images</label>

  {/* Image 1 */}
  <div className="mt-3">
    <p>Previously uploaded image: {formData.product_image ? formData.product_image.name || formData.product_image : "No image uploaded"}</p>
    <input
      className="form-control mt-3"
      type="file"
      name="product_image"
      onChange={handleFileChange}
    />
  </div>

  {/* Image 2 */}
  <div className="mt-3">
    <p>Previously uploaded image 2: {formData.product_image2 ? formData.product_image2.name || formData.product_image2 : "No image uploaded"}</p>
    <input
      className="form-control mt-3"
      type="file"
      name="product_image2"
      onChange={handleFileChange}
    />
  </div>

  {/* Image 3 */}
  <div className="mt-3">
    <p>Previously uploaded image 3: {formData.product_image3 ? formData.product_image3.name || formData.product_image3 : "No image uploaded"}</p>
    <input
      className="form-control mt-3"
      type="file"
      name="product_image3"
      onChange={handleFileChange}
    />
  </div>

  {/* Image 4 */}
  <div className="mt-3">
    <p>Previously uploaded image 4: {formData.product_image4 ? formData.product_image4.name || formData.product_image4 : "No image uploaded"}</p>
    <input
      className="form-control mt-3"
      type="file"
      name="product_image4"
      onChange={handleFileChange}
    />
  </div>
</div>


      <p className="text-green mb-3">(you can upload 4 photos & 1 Video URL)</p>
              {/* Video URL input */}
      <div className="mb-4">
        <label className="form-label">Product Video URL</label>
        <input
          className="form-control"
          type="text"
          placeholder="Enter video URL"
          value={productVideoUrl}
          onChange={handleVideoUrlChange}
        />
        {formData.product_video && (
  <p className="mt-2">Previously uploaded: {formData.product_video}</p>
)}
      </div>
                  <div>
                  <label htmlFor="sub_category_name">Select Subcategory:</label>
                  <select
                    id="sub_category_name"
                    name="sub_category_name"
                    value={formData.sub_category_name}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option key="default" value="">Select Subcategory</option>
                    {subcategories.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.sub_category_name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
              </div>
            </div>
          </div>
      </form>
    </section>
  );
};

export default EditProductMain;
