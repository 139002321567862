import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-center mb-2 mb-sm-5 m-4">Page Not Found</h4>
          <img
            style={{ width: "100%", height: "300px", objectFit: "contain" }}
            src="/images/not-found.png"
            alt="Not-found"
          />
          <button className="col-md-3 col-sm-6 col-12 btn btn-success mt-5">
            <Link to="/admin/login" className="text-white text-decoration-none">
              Admin Login
            </Link>
          </button>
          <button className="col-md-3 col-sm-6 col-12 btn btn-success mt-5 ml-2">
            <Link to="/sellerlogin" className="text-white text-decoration-none">
             Seller Login
            </Link>
          </button>
          <button className="col-md-3 col-sm-6 col-12 btn btn-success mt-5 ml-2">
            <Link to="/login" className="text-white text-decoration-none">
              User Login
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
