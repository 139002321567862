import { Counter } from "./features/counter/Counter";
import './App.css';
import './user/App.css';
import { React, useState } from "react";
import ReactDOM from 'react-dom';
import { useRecoilState , RecoilRoot } from 'recoil'
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import OtpPage from "./pages/OtpPage";
import SellerLoginPage from "./pages/SellerLoginPage";
import SellerSignupPage from "./pages/SellerSignupPage";
import SellerOtpPage from "./pages/SellerOtpPage";
import SellerOtp2Page from "./pages/SellerOtp2Page";
import SellerLogout from "./features/auth/components/SellerLogout";
import SellerFormPage from "./pages/SellerFormPage";
import Logout from '../src/features/auth/components/Logout';
import AdminLogout from "./features/auth/components/AdminLogout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UCart from "./features/Cart/Cart";
import About from '../src/user/PAGES/Extra/About';
import Contact from '../src/user/PAGES/Extra/Contact';
import UserProfile from './user/PAGES/User/UserProfile';
import FAQ from './user/PAGES/Extra/FAQ';
import Termsandconditions from './user/PAGES/Extra/Termsandconditions';
import PrivacyPolicy from './user/PAGES/Extra/PrivacyPolicy';
import AccountSettings from "./user/COMPONENTS/UserProfile/AccountSettings";
import LegalNotice from "./user/COMPONENTS/UserProfile/LegalNotice";
import UserAddress from "./user/COMPONENTS/UserProfile/UserAddress";
import UserSidebar from "./user/COMPONENTS/UserProfile/UserSidebar";
import YourOrders from "./user/COMPONENTS/UserProfile/YourOrders";
import OrderSuccessful from "./user/COMPONENTS/Order/OrderSuccessful";
import Invoice from "./user/COMPONENTS/Order/invoice";
import AllProduct from "./user/COMPONENTS/Product/AllProduct";
import ProductPage from "./user/PAGES/Product/ProductPage";
import AllProducts from "./user/COMPONENTS/ProductMain/AllProducts";

// guest user
import AllProduct1 from "./guestuser/Product/AllProduct";
import ProductPage1 from "./guestuser/PAGES/Product/ProductPage";
import AllProducts1 from "./guestuser/ProductMain/AllProducts";


import Apps from "./features/footer/Componnent";
import Apps1 from "./features/footer/Componnent1";
import {
  BrowserRouter as Router,
  RouterProvider,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import CartPage from "./pages/CartPage";
import FrontPage from "./pages/FrontPage";
import Front from "./features/front/slider/Front";
import Seller from "./features/seller/components/Seller";
import SellerPage from "./pages/SellerPage";
import SellerPageCopy from "./pages/SellerPagecopy";
import SellerForm from "./features/auth/components/SellerForm";
import Homeslider from "./features/home/slider/Home";
import HomePage from "./pages/HomePage";


import "./admin/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./admin/screens/HomeScreen";
import ProductScreen from "./admin/screens/ProductScreen";
import CategoriesScreen from "./admin/screens/CategoriesScreen";
import OrderScreen from "./admin/screens/OrderScreen";
import OrderDetailScreen from "./admin/screens/OrderDetailScreen";
import AAccountSettings from "./admin/components/AdminProfile/AAccountSettings";
import ALegalNotice from "./admin/components/AdminProfile/ALegalNotice";
import AUserAddress from "./admin/components/AdminProfile/AUserAddress";
import AUserSidebar from "./admin/components/AdminProfile/AUserSidebar";
import AUserProfile from "./admin/PAGES/User/AUserProfile";
import AddProduct from "./admin/screens/AddProduct";
import Login from "./admin/screens/LoginScreen";
import AdminSignupPage from "./pages/AdminSignupPage";
import AdminOtpPage from "./pages/AdminOtpPage";
import UsersScreen from "./admin/screens/UsersScreen";
import SellerScreen from "./admin/screens/SellerScreen";
import ProductEditScreen from "./admin/screens/ProductEditScreen";
import NotFound from "./admin/screens/NotFound";
import "./admin/adminapp.css";
import PromoPage from "./admin/screens/promopage";
import Promotion from "./admin/components/promotion";
import Approve from "./admin/components/productapprove/Approved";
import Banner from "./admin/components/banner";
import BannerPage from "./admin/screens/bannerpage";
import Top from "./admin/components/topselection";
import TopPage from "./admin/screens/TopPage";
import ApprovePage from "./admin/screens/approvePage";
import Approve2 from "./admin/components/productapprove/Approve2";

import SHomeScreen from "../src/seller/screens/HomeScreen";
import SAccountSettings from "./seller/components/SellerProfile/SAccountSettings";
import SLegalNotice from "./seller/components/SellerProfile/SLegalNotice";
import SellerComponent from "./admin/components/sellers/Sellercomponent";
import SUserAddress from "./seller/components/SellerProfile/SUserAddress";
import SUserSidebar from "./seller/components/SellerProfile/SUserSidebar";
import SUserProfile from "./seller/PAGES/User/SUserProfile";
import SProductScreen from "../src/seller/screens/ProductScreen";
import SCategoriesScreen from "../src/seller/screens/CategoriesScreen";
import SOrderScreen from "./seller/screens/OrderScreen";
import SOrderDetailScreen from "./seller/screens/OrderDetailScreen";
import SAddProduct from "./seller/screens/AddProduct";
import SProductEditScreen from "./seller/screens/ProductEditScreen";
import SNotFound from "./seller/screens/NotFound";
import "../src/seller/responsive.css";
import '../src/seller/sellerapp.css';
import EditCategory from "./admin/components/Categories/EditCategory";
import SShiprocket from "./seller/screens/ShiprocketScreen";
import ASShiprocket from "./admin/screens/AShiprocketScreen";
import AShiprocket from "./admin/components/AShiprocket";
// import Apps1 from "./features/footer/Componnent1";
// import { SearchProvider } from "../src/features/navbar/SearchContext";

function App() {
  return (
    <RecoilRoot>
      {/* <SearchProvider> Wrap the application with the SearchProvider */}
    <>
    <Router>
        <Routes> 
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/logout/:pk" element={<Logout/>} />
          <Route path="/signup" element={<SignupPage/>} />
          <Route path='/otp/:pk/:name/' element={<OtpPage/>} />
          <Route path="/cart/:pk/:name/" element={<UCart/>} />                
          <Route path="/front/:pk/:name/" element={<FrontPage/>} />          
          <Route path="/" element={<HomePage/>} /> 
      
          <Route path="/footer/:pk/:name/" element={<Apps/>} /> 
          <Route path="/footer/" element={<Apps1/>} /> 
          
        <Route path="/user/termsandconditions" element={<Termsandconditions/>} />
        <Route path='/user/UserProfile/:pk/:name/' element={<UserProfile/>} />
        <Route path="/user/AccountSettings/:pk/:name/" element={<AccountSettings/>}/>
        <Route path="/user/yourorders/:pk/:name/" element={<YourOrders/>}/>
        <Route path="/user/ordersuccessful/:pk/:orderId/:name/" element={<OrderSuccessful/>}/>
        <Route path="/user/invoice/:pk/:orderId/:name/" element={<Invoice/>}/>
        <Route path="/user/address/:pk/:name/" element={<UserAddress/>}/>
        <Route path="/user/LegalNotice/:pk/:name/" element={<LegalNotice/>}/>
        <Route path="/user/allproduct/:pk/:id/:name/" element={<AllProduct/>}/>
        <Route path="/user/productpage/:pk/:id/:name/" element={<ProductPage/>}/>
        <Route path="/user/allproducts/:pk/:name/" element={<AllProducts/>}/>
  {/* guestuser */}
        <Route path="/user/allproduct1/:id" element={<AllProduct1/>}/>
        <Route path="/user/productpage1/:id" element={<ProductPage1/>}/>
        <Route path="/user/allproducts1/" element={<AllProducts1/>}/>
       
     

          <Route path="/admin/:pk" element={<HomeScreen/>} />
          <Route path='/admin/Profile/:pk' element={<AUserProfile/>} />
          <Route path="/admin/AccountSettings/:pk" element={<AAccountSettings/>}/>
          <Route path="/admin/address/:pk" element={<AUserAddress/>}/>
          <Route path="/admin/LegalNotice/:pk" element={<ALegalNotice/>}/>
          <Route path="/adminlogout/:pk" element={<AdminLogout/>} />
          <Route path="/admin/products/:pk" element={<ProductScreen />} />
          <Route path="/admin/category/:pk" element={<CategoriesScreen />} />
          <Route path="/admin/orders/:pk" element={<OrderScreen />} />
          <Route path="/admin/promo/:pk" element={<PromoPage/>} />
          <Route path="/admin/approve/:pk" element={<ApprovePage/>} />
          <Route path="/admin/banner/:pk" element={<BannerPage/>} />
          <Route path="/admin/top/:pk" element={<TopPage/>} />
          <Route path="/admin/order/:pk/:id" element={<OrderDetailScreen />} />
          <Route path="/admin/users/:pk" element={<UsersScreen />} />
          <Route path="/admin/sellers/:pk" element={<SellerScreen />} />
          <Route path="/admin/addproduct/:pk" element={<AddProduct />} />
          <Route path="/admin/product/edit/:pk/:id" element={<ProductEditScreen />} />
          <Route path="/adminotp/:pk" element={<AdminOtpPage/>} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/adminsignup" element={<AdminSignupPage/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/admin/shiprocket/:pk/:id" element={<ASShiprocket />} />
          <Route path="/admin/category/editcategory/:pk/:id" element={<EditCategory  />} />
        
          <Route path="/seller/:pk/:name" element={<SellerPage/>} />          
          <Route path="/seller" element={<SellerPageCopy/>} />          
        <Route path='/seller/Profile/:pk' element={<SUserProfile/>} />
          <Route path="/seller/AccountSettings/:pk" element={<SAccountSettings/>}/>
          <Route path="/seller/address/:pk" element={<SUserAddress/>}/>
          <Route path="/seller/LegalNotice/:pk/" element={<SLegalNotice/>}/>
          <Route path="/sellerdash/:pk" element={<SHomeScreen/>} />
          <Route path="/seller/products/:pk" element={<SProductScreen />} />
          <Route path="/seller/category/:pk" element={<SCategoriesScreen />} />
          <Route path="/seller/orders/:pk" element={<SOrderScreen />} />
          <Route path="/seller/order/:pk/:id" element={<SOrderDetailScreen />} />
          <Route path="/seller/addproduct/:pk" element={<SAddProduct />} />
          <Route path="/seller/product/edit/:pk/:id" element={<SProductEditScreen />} />
          <Route path="/seller/shiprocket/:pk/:id" element={<SShiprocket />} />
          <Route path="/sellerotp/:pk" element={<SellerOtpPage/>} />
          <Route path="/sellerotp2/:pk" element={<SellerOtp2Page/>} />
          <Route path="/sellerform/:pk" element={<SellerFormPage/>} />
          <Route path="/sellerlogout/:pk" element={<SellerLogout/>} />
          <Route path="/sellerlogin" element={<SellerLoginPage/>} />
          <Route path="/sellersignup" element={<SellerSignupPage/>} />
          <Route path="*" element={<SNotFound />} />
        </Routes>
      </Router>
    </>
    </RecoilRoot>
  );
}

export default App;
