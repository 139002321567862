import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const AddProductMain = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const { pk } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    weight: '',
    height: '',
    size: '',
    colour: '',
    stock_keeping_unit: '',
    product_name: '',
    actual_price: '',
    discount_price: '',
    in_stock: '',
    description: '',
    Details: '',
    highlights: '',
    product_image: '',
    product_image2: '',
    product_image3: '',
    product_image4: '', // Keep this for image upload
    product_video: '' ,// Add product_video to formData
    dilevery_charges: '',  dilevery_charges: '', // Add delivery charges field
  });

  const [imageInputs, setImageInputs] = useState([{ id: 1, file: null }, { id: 2, file: null }, { id: 3, file: null }, { id: 4, file: null }]);
  
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_sub_categories/');
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };
    
    fetchSubcategories();
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };
  
   // Replace the previous product video state and handleVideoChange
   const [productVideoUrl, setProductVideoUrl] = useState(''); // Initialize state for video URL 
   const handleVideoUrlChange = (e) => {
     setProductVideoUrl(e.target.value); // Set the video URL from input
   };
 
   const handleSubmit = async (event) => {
     event.preventDefault();
     const form = new FormData();
 
     // Append text fields to FormData
     for (const key in formData) {
       if (formData[key] !== null && formData[key] !== undefined) {
         form.append(key, formData[key]);
       }
     }
 
     // Append image files to FormData
     imageInputs.forEach((input, index) => {
       if (input.file) {
         form.append(`product_image${index + 1}`, input.file);
       }
     });
 
     // Append product video URL to FormData
     if (productVideoUrl) {
       form.append('product_video', productVideoUrl);
     }
 
     // Append selected subcategory
     if (selectedSubcategory) {
       form.append('sub_category_name', selectedSubcategory);
     } else {
       console.error('No subcategory selected');
       return;
     }
 
     try {
       const response = await axios.post(`https://terracharms.com/create_admin_product/${pk}/`, form, {
         headers: {
           'Content-Type': 'multipart/form-data',
         },
       });
 
       console.log("Response:", response.data); // Log full response for better debugging
 
       if (response.status === 200) {
        toast.success("🎉 Congratulations! Your product has been created successfully!");
        navigate(`/admin/products/${pk}`);
      }
    }catch (error) {
       console.error('Error submitting form:', error.response ? error.response.data : error.message);
     }
   };
 

  return (
    <>
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={handleSubmit}>
          <div className="content-header">
            <Link to={`/admin/products/${pk}`} className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title">Add product</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="product_name"
                      name="product_name"
                      value={formData.product_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="actual_price" className="form-label">
                      Actual Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="actual_price"
                      name="actual_price"
                      value={formData.actual_price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="discount_price" className="form-label">
                      Discount Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="discount_price"
                      name="discount_price"
                      value={formData.discount_price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="in_stock" className="form-label">
                      In Stock
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="in_stock"
                      name="in_stock"
                      value={formData.in_stock}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="weight"
                      placeholder="Weight"
                      value={formData.weight}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                    <input
                      type="text"
                      name="height"
                      placeholder="Height"
                      value={formData.height}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                    <select
                      className="form-select mt-2"
                      name="size"
                      value={formData.size}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Size</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                      <option value="Extra Large">Extra Large</option>
                    </select>
                    <select
                      className="form-select mt-2"
                      name="colour"
                      value={formData.colour}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Colour</option>
                      <option value="Red">Red</option>
                      <option value="Green">Green</option>
                      <option value="Black">Black</option>
                      <option value="White">White</option>
                      <option value="Brown">Brown</option>
                      <option value="Purple">Purple</option>
                      <option value="Beige">Beige</option>
                    </select>
                    <input
                      type="text"
                      name="stock_keeping_unit"
                      placeholder="SKU"
                      value={formData.stock_keeping_unit}
                      onChange={handleInputChange}
                      className="form-control mt-2"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Details</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="Details"
                      value={formData.Details}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Highlights</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      name="highlights"
                      value={formData.highlights}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/* Dilevery_charges Input */}
      <div className="mb-4">
        <label className="form-label">Delivery Charges</label>
        <input
          className="form-control"
          type="number"
          name="dilevery_charges"
          placeholder="Enter dilevery_charges"
          value={formData.dilevery_charges}
          onChange={handleInputChange}
        />
      </div>
                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      className="form-control mt-3"
                      type="file"
                      name="product_image"
                      onChange={handleFileChange}
                    />
                    <input
                      className="form-control mt-3"
                      type="file"
                      name="product_image2"
                      onChange={handleFileChange}
                    />
                    <input
                      className="form-control mt-3"
                      type="file"
                      name="product_image3"
                      onChange={handleFileChange}
                    />
                    <input
                      className="form-control mt-3"
                      type="file"
                      name="product_image4" // Input for product_image4
                      onChange={handleFileChange}
                    />
                  </div>

              {/* Video URL input */}
      <div className="mb-4">
        <label className="form-label">Product Video URL</label>
        <input
          className="form-control"
          type="text"
          placeholder="Enter video URL"
          value={productVideoUrl}
          onChange={handleVideoUrlChange}
        />
      </div>

                  <div className="mb-4">
                    <label className="form-label">Select Subcategory</label>
                    <select
                      className="form-select"
                      value={selectedSubcategory}
                      onChange={(e) => setSelectedSubcategory(e.target.value)}
                    >
                      <option value="">Choose a subcategory</option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.sub_category_name}>
                          {subcategory.sub_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
