import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import Approve from "../components/productapprove/Approved";

const ApprovePage = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <Approve/>
      </main>
    </>
  );
};

export default ApprovePage;
