import React from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const Approve2 = ({ product, index, handleEditClick }) => {
  const { pk } = useParams();

  // Function to handle the approval action
  const handleApprove = async () => {
    // Check if the product is already approved
    if (product.is_approved === "true") {
      toast.info("Product is already approved."); // Show info message
      return; // Exit the function early
    }

    try {
      const response = await axios.patch(`https://terracharms.com/approving_seller_product/${pk}/${product.id}/`);
      toast.success("Product approved successfully."); // Show success message in toast
      window.location.reload();
    } catch (error) {
      console.error("Error approving product:", error);
      toast.error("Error approving product. Please try again."); // Show error message in toast
    }
  };

  return (
    <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
      <div className="card card-product-grid shadow-sm">
        <Link to="#" className="img-wrap">
          <img src={`https://terracharms.com/${product.product_image}`} alt="Product" />
        </Link>
        <div className="info-wrap">
          <Link to="#" className="title text-truncate">
            {product.product_name}
          </Link>
          <div className="price mb-2">₹{product.discount_price}</div>
          
          {/* Conditional rendering based on is_approved status */}
          <div className=" mb-2">
            {product.is_approved === "true" ? "Already Approved" : "Not Approved"}
          </div>
          
          <div className="row">
            <button
              className="btn btn-sm btn-outline-success item-center col-md-6"
              onClick={handleApprove} // Call the approve function on click
              // disabled={product.is_approved === "true"} // Disable button if already approved
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
    </div>
  );
};

export default Approve2;
