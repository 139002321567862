import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountSettings = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile_no: '',
    email: '',
  });
  const { pk, name } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/user_profile/${pk}/`);
        const data = response.data;
        setFormData({
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          Mobile_no: data.Mobile_no || '',
          email: data.email || '',
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [pk]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Display confirmation toast
    const isConfirmed = await new Promise((resolve) => {
      toast.info(
        <div>
          <p>Are you sure you want to save the changes?</p>
          <button onClick={() => resolve(true)} style={styles.yesButton}>Yes</button>
          <button onClick={() => resolve(false)} style={styles.noButton}>No</button>
        </div>,
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: true,
          draggable: false,
        }
      );
    });

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await axios.patch(`https://terracharms.com/edit_user_profile/${pk}/`, formData);
      console.log('Edit Response:', response.data);
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <ToastContainer />
      <div className="content-header mt-10 ml-6">
        <Link to={`/user/UserProfile/${pk}/${name}/`} className="btn btn-dark text-white">
          Back To Profile
        </Link>
      </div>
      <div style={styles.accountSettings}>
        <h1 style={styles.mainHead1}>Personal Information</h1>

        <div style={styles.form}>
          <form onSubmit={handleSubmit}>
            <div style={styles.formGroup}>
              <label htmlFor='first_name'>
                First Name <span>*</span>
              </label>
              <input
                type='text'
                name='first_name'
                id='first_name'
                value={formData.first_name}
                onChange={handleInputChange}
                style={styles.input}
                required
              />
            </div>

            <div style={styles.formGroup}>
              <label htmlFor='last_name'>
                Last Name <span>*</span>
              </label>
              <input
                type='text'
                name='last_name'
                id='last_name'
                value={formData.last_name}
                onChange={handleInputChange}
                style={styles.input}
                required
              />
            </div>

            <div style={styles.formGroup}>
              <label htmlFor='Mobile_no'>Phone/Mobile*</label>
              <input
                type='text'
                name='Mobile_no'
                id='Mobile_no'
                value={formData.Mobile_no}
                onChange={handleInputChange}
                style={styles.input}
                required
              />
            </div>

            <div style={styles.formGroup}>
              <label htmlFor='email'>
                Email <span>*</span>
              </label>
              <input
                type='email'
                name='email'
                id='email'
                value={formData.email}
                onChange={handleInputChange}
                style={styles.input}
                required
              />
            </div>

            <button type='submit' style={styles.button}>
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const styles = {
  accountSettings: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '80vh',
    padding: '10px',
    backgroundColor: '#f4f4f4',
  },
  mainHead1: {
    fontSize: '2rem',
    marginBottom: '30px',
    color: '#333',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  form: {
    backgroundColor: '#ffffff',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '600px',
  },
  formGroup: {
    marginBottom: '20px',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '15px',
    fontSize: '1.2rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease',
    height: 'auto',
  },
  button: {
    width: '100%',
    marginTop: '10px',
    padding: '20px',
    backgroundColor: '#28a745',
    border: 'none',
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  yesButton: {
    backgroundColor: 'green',
    color: 'white',
    padding: '8px 16px',
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
  },
  noButton: {
    backgroundColor: 'red',
    color: 'white',
    padding: '8px 16px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
  },
};

export default AccountSettings;
