import UserSidebar from '../../COMPONENTS/UserProfile/UserSidebar'
import AccountSettings from '../../COMPONENTS/UserProfile/AccountSettings'
import './UserProfile.css'
import YourOrders from '../../COMPONENTS/UserProfile/YourOrders'
import UserAddress from '../../COMPONENTS/UserProfile/UserAddress'
import Apps from '../../../features/footer/Componnent'
import Footer from '../../../features/footer/Footer';
import Navbar from '../../../features/navbar/Navbar'
import { useNavigate , useParams , Link} from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
const UserProfile = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { pk,name} = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
    const {activepage} = useParams()
    useEffect(() => {
      if (searchTerm.trim() !== '') {
        const fetchSearchResults = async () => {
          try {
            const response = await axios.get(
              `https://terracharms.com/user_search_get/${pk}/`,
              {
                params: {
                  search: searchTerm
                }
              }
            );
            setProducts(response.data);
    
            // Redirect to the search results page
            navigate(`/user/allproducts/${pk}/${name}/`);
          } catch (error) {
            console.error('Error fetching search results:', error);
          }
        };
    
        fetchSearchResults();
      }
    }, [searchTerm, pk, name,navigate, setProducts]);
    
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
      
    return (
      <div>
      <Navbar onSearch={setSearchTerm} />
      <div className='userprofile'>
      <div className="content-header mt-10 ml-6">
        <Link to={`/front/${pk}/${name}/`} className="btn btn-dark text-white">
          Back To Home
        </Link>
      </div>
    
         <div className=' .main-content'>
            <div className='left'>
              <UserSidebar activepage={activepage}/>
            </div>
              {activepage === 'accountsettings/:pk/:name/' && <AccountSettings/>}
              {activepage === '/user/yourorders/:pk/:name/' && <YourOrders/>}
              {activepage === '/user/address/:pk/:name/' && <UserAddress/>}
              {activepage === '/footer/:pk/:name/' && <Apps/>}
         </div>
         <Footer></Footer>
        </div>
    </div>
  )
}

export default UserProfile