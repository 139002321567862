import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const ProductCard = ({ product }) => {
  const { pk, name } = useParams(); // Get route parameters
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // To hold search input

  // Fetch products based on a search query
  const fetchProducts = async (search) => {
    if (search) { // Only fetch if a query is provided
      try {
        const response = await axios.get(`https://terracharms.com/user_search_get/${pk}`, {
          params: { search: searchTerm },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    } else {
      setProducts([]); // Clear products if query is empty
    }
  };

  useEffect(() => {
    fetchProducts(searchTerm); // Trigger fetching based on `searchQuery`
  }, [searchTerm]); // Only run when `searchQuery` changes

  return (
    <>
      {/* Ensure no underline with inline style */}
      <Link
        to={`/user/productpage/${pk}/${product.id}/${name}/`}
        style={{ textDecoration: 'none' }} // Remove underline from the link
        className="no-underline"
      >
        <div className="shadow-lg bg-white rounded-lg p-4 hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer">
          <div className="group relative bg-white">
            {/* Product Image */}
            <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
              <img
                alt={product.product_name}
                src={`https://terracharms.com${product.product_image}`}
                className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
              />
            </div>

            {/* Product Details */}
            <div className="mt-4 text-center">
              <h3 className="text-truncate text-sm sm:text-base font-semibold text-gray-800">
                {product.product_name}
              </h3>

              {/* Product Prices */}
              <div className="price-container mt-2">
                <p className="text-l text-teal-600">
                  ₹{product.discount_price.toFixed(2)}{" "}
                  <span className="line-through ml-2 text-red-400">
                    ₹{product.actual_price.toFixed(2)}
                  </span>
                </p>

                {/* Discount Badge */}
                {product.discount_price && product.actual_price && (
                  <span className="discount-box text-sm ml-2 text-white bg-green-700 px-2 py-1 rounded-lg">
                    {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                  </span>
                )}
              </div>

              {/* Product Size */}
              <div className="text-gray-700 text-sm mt-2">
                Size: {product.size}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductCard;
