import Slider from "react-slick";
import "./index.css";
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import { useNavigate , useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar2 from "../../navbar/Navbar2"; // Ensure this import is correct
import Swal from 'sweetalert2'; // Import SweetAlert2

const Front = (sub_category_name_id) => {
  const [latestProducts, setLatestProducts] = useState([]);
  const [bestsellersProducts, setBestsellersProducts] = useState([]);
  const [callouts, setCallouts] = useState([]);
  const [userBanners, setUserBanners] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [headline, setHeadline] = useState('');
  const [insight, setInsight] = useState('');
  const { pk, name } = useParams();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Fetch products
  useEffect(() => {
    const fetchLatestProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/latest_product/');
        setLatestProducts(response.data);
      } catch (error) {
        console.error('Error fetching latest products:', error);
      }
    };
    fetchLatestProducts();
  }, []);

  // Fetch bestsellers
  useEffect(() => {
    const fetchBestsellersProducts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/bestsellers_Domestic_Harmony/');
        setBestsellersProducts(response.data);
      } catch (error) {
        console.error('Error fetching bestsellers products:', error);
      }
    };
    fetchBestsellersProducts();
  }, []);

  // Fetch callouts from the API
  useEffect(() => {
    const fetchCallouts = async () => {
      try {
        const response = await axios.get('https://terracharms.com/get_user_selection/');
        const data = response.data;
        const formattedData = data.map((item) => ({
          pk: item.pk,
          id: item.id,
          name: item.product_name,
          description: item.description || 'No description available',
          imageSrc: `https://terracharms.com${item.product_image}`,
          imageAlt: `${item.product_name} image`,
          actual_price: item.actual_price,
          discount_price: item.discount_price,
          href: `/user/productpage/${item.pk}/${item.id}/${item.product_name}/`,
        }));
        setCallouts(formattedData);
      } catch (error) {
        console.error('Error fetching callouts:', error);
      }
    };
    fetchCallouts();
  }, []);

  const handleProductClick = (id) => {
    navigate(`/user/productpage1/${id}/`);
  };

  // Fetch user banners
  const fetchUserBanners = async () => {
    axios.get(`https://terracharms.com/get_user_banner/`)
      .then(response => setUserBanners(response.data))
      .catch(error => console.error('Error fetching banners:', error));
  };
  
  useEffect(() => {
    axios.get('https://terracharms.com/get_user_promotion/')
      .then(response => {
        const data = response.data;
        setPromotions(data);

        // Use the first promotion for headline and insight if available
        if (data.length > 0) {
          setHeadline(data[0].Headline || 'Discover the Art of Living');
          setInsight(data[0].Insight || 'This year, clearing and creating living spaces that are visually appealing and more attractive to the human eye.');
        }
      })
      .catch(error => {
        console.error('There was an error fetching the promotions!', error);
      });
  }, []);

  const handlebestsellerclick = (id) => {
    navigate(`/user/productpage1/${id}/`);
  };

  const handlelatestclick = (id) => {
    navigate(`/user/productpage1/${id}/`);
  };

  useEffect(() => {
    fetchUserBanners();
  }, []);

  // Desktop settings
  const desktopSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Mobile settings
  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Handler to navigate to the AllProduct page for banners
  const handleBannerClick = (sub_category_name_id) => {
    navigate(`/user/allproduct1/${sub_category_name_id}/`);
  };

  // Handler to navigate to the AllProduct page for promotions
  const handlePromotionClick = (sub_category_name_id) => {
    navigate(`/user/allproduct1/${sub_category_name_id}/`);
  };

  const count = 1;

  useEffect((query) => {
    if (searchTerm.trim() !== '') {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(`https://terracharms.com/search_api/`, {
            params: { query: query },
          });
          setProducts(response.data);
          // Redirect to the search results page
          navigate(`/user/allproducts1/`);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };
      fetchSearchResults();
    }
  }, [searchTerm, navigate, setProducts]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

return (
  <>
  <Navbar2 onSearch={setSearchTerm} />

  <section className="homeslider bg-grey-100">
<div className="container-h-90 w-100">
  <Slider {...settings}>
    {userBanners.length > 0 ? (
      userBanners.map((banner) => (
        <div
          key={banner.sub_id} // Ensure unique key
          className="item h-80 cursor-pointer "
          onClick={() => handleBannerClick(banner.sub_category_name_id)} // Pass sub_id
        >
          <img
            src={`https://terracharms.com${banner.Banner_image}`}
            className="w-100 h-80"
            alt={`Banner ${banner.sub_category_name_id}`}
          />
        </div>
      ))
    ) : (
      <p>No banners available</p>
    )}
  </Slider>
</div>

{/* Desktop Version */}
<div
  className="relative overflow-hidden mt-4 mb-8 ml-5 mr-5 lg:block hidden"
  onClick={() => handlePromotionClick(promotions[0].sub_category_name_id)} // Redirect on click anywhere
>
  <div className="pb-24 pt-6 bg-gradient-to-r from-purple-200 to-pink-500 max-h-7xl">
    <div className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      <div className="sm:max-w-lg">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {headline}
        </h1>
        <p className="mt-4 text-xl text-gray-500">{insight}</p>
      </div>
      <div>
        <div
          aria-hidden="true"
          className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
        >
          <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 mt-10">
            <div className="flex items-center space-x-6">
              {promotions.length > 0 ? (
                promotions.map((promotion, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-3 gap-x-2 gap-y-4 mb-1 h-full w-full"
                  >
                    {[
                      promotion.promotion_image1,
                      promotion.promotion_image2,
                      promotion.promotion_image3,
                      promotion.promotion_image4,
                      promotion.promotion_image5,
                      promotion.promotion_image6,
                    ].map((image, idx) =>
                      image ? (
                        <div
                          key={idx}
                          className="relative h-48 w-48 overflow-hidden rounded-lg border border-gray-500 p-2 transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                        >
                          <img
                            alt={`Promotion ${idx + 1}`}
                            src={`https://terracharms.com${image}`}
                            className="h-full w-full object-cover object-center transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                          />
                        </div>
                      ) : null
                    )}
                  </div>
                ))
              ) : (
                <p>No promotions available</p>
              )}
            </div>
          </div>
        </div>

        {promotions.length > 0 && (
          <a
            href="#"
            className="inline-block mt-16 ml-4 rounded-md border border-transparent bg-blue-900 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor behavior
              handlePromotionClick(promotions[0].sub_category_name_id); // Trigger navigation
            }}
          >
            Shop Collection
          </a>
        )}
      </div>
    </div>
  </div>
</div>

{/* Desktop Version */}
<div className="relative overflow-hidden bg-blue-200 mb-4 mx-4 sm:mx-6 lg:mx-8 lg:block hidden">
  <div className="pt-4 bg-blue-200">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">
      Latest Products
    </h2>
    <div className="mt-2 mb-6">
      <Slider
        {...settings1}
        className="flex pl-4 pr-4"
      >
        {latestProducts.length > 0 ? (
          latestProducts.map((product) => (
            <div className="p-2" key={product.id}>
              <div
                className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
                onClick={() => handlelatestclick(product.id, product.pk)}
              >
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>

                <div className="mt-4 text-center">
                  <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                    {product.product_name}
                  </h3>
                  <div className="price-container mt-2">
                    <p className="price text-sm text-gray-600">
                      ₹{(product.discount_price * count).toFixed(2)}{" "}
                      <span className="actual-price line-through text-gray-500">
                        ₹{(product.actual_price * count).toFixed(2)}
                      </span>
                    </p>
                    {product.discount_price && product.actual_price && (
                      <span className="discount-box text-sm text-white">
                        {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No latest products available</p>
        )}
      </Slider>
    </div>
  </div>
</div>

{/* Desktop Version */}
<div className="relative overflow-hidden bg-teal-100 mb-4 mx-4 sm:mx-6 lg:mx-8 lg:block hidden">
  <div className="pt-4 bg-teal-100">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">
      Best Seller on Domestic Harmony
    </h2>
    <div className="mt-2 mb-6">
      <Slider {...settings1} className="flex pl-4 pr-4">
        {bestsellersProducts.length > 0 ? (
          bestsellersProducts.map((product) => (
            <div className="p-2" key={product.id}>
              <div
                className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
                onClick={() => handlebestsellerclick(product.id, product.pk)}
              >
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>

                <div className="mt-4 text-center">
                  <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                    {product.product_name}
                  </h3>
                  <div className="price-container mt-2">
                    <p className="price text-sm text-gray-600">
                      ₹{(product.discount_price * count).toFixed(2)}{" "}
                      <span className="actual-price line-through text-gray-500">
                        ₹{(product.actual_price * count).toFixed(2)}
                      </span>
                    </p>
                    {product.discount_price && product.actual_price && (
                      <span className="discount-box text-sm text-white">
                        {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No bestsellers available</p>
        )}
      </Slider>
    </div>
  </div>
</div>

{/* Desktop Version */}
<div className="hidden lg:block ml-6 mr-6 bg-gradient-to-r from-violet-200 to-fuchsia-300 overflow-hidden mt-4 mb-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mt-4 text-left">
            Top Selection
          </h2>
          <div className="px-4 py-4 grid grid-cols-1 gap-y-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {callouts.length > 0 ? (
              callouts.map((callout) => (
                <div
                  key={callout.name}
                  className="group relative overflow-hidden bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
                  onClick={() => handleProductClick(callout.id, callout.pk)}
                >
                  <div className="relative h-80 w-full">
                    <img
                      alt={callout.imageAlt}
                      src={callout.imageSrc}
                      className="h-full w-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-black opacity-25 transition-opacity duration-300 group-hover:opacity-0" />
                  </div>
                  <div className="p-4">
                    <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                      <a href={callout.href} className="hover:text-fuchsia-500 transition-colors duration-200">
                        {callout.name}
                      </a>
                    </h3>
                    <div className="mt-2">
                      <p className="text-xl text-left font-bold text-gray-900">
                        ₹{callout.discount_price ? callout.discount_price.toFixed(2) : callout.actual_price.toFixed(2)}
                        <span className="line-through text-gray-500 ml-2">
                          {callout.discount_price ? `₹${callout.actual_price.toFixed(2)}` : ''}
                        </span>
                      </p>
                      {callout.discount_price && callout.actual_price && (
                        <span className="inline-block bg-fuchsia-600 text-white text-sm px-2 py-1 rounded-full mt-1">
                          {Math.round(((callout.actual_price - callout.discount_price) / callout.actual_price) * 100)}% OFF
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button className="bg-fuchsia-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-fuchsia-600 transition-colors duration-300">
                      View Product
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-700">No selections available.</p>
            )}
          </div>
        </div>
      </div>

{/* Mobile Version */}
<div className="relative overflow-hidden mt-4  lg:hidden">
  <div className="pb-4 pt-6 bg-gradient-to-r from-purple-200 to-pink-500 max-h-7xl">
    <div className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      <div className="sm:max-w-lg">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {headline}
        </h1>
        <p className="mt-4 text-xl text-gray-500">
          {insight}
        </p>
      </div>
      {/* <div className="relative overflow-hidden mt-4 mb-8 mx-4 sm:mx-6 lg:mx-8"> */}
  <div className="pb-4 pt-16">
    {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> */}
          {/* Responsive positioning for the promotion images */}
      <div className="relative">
        <div className="flex flex-wrap gap-4 overflow-x-auto py-4">
          {promotions.length > 0 ? (
            promotions.map((promotion, index) => (
              <div
                key={index}
                className="flex-shrink-0 flex gap-4"
              >
                {[
                  promotion.promotion_image1,
                  promotion.promotion_image2,
                  promotion.promotion_image3,
                  promotion.promotion_image4,
                  promotion.promotion_image5,
                  promotion.promotion_image6,
                ].map((image, idx) => (
                  image ? (
                    <div
                      key={idx}
                      className="relative w-48 h-48 overflow-hidden rounded-lg border border-gray-500 p-2 transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                      onClick={() => handlePromotionClick(promotion.sub_category_name_id)}
                    >
                      <img
                        alt={`Promotion ${idx + 1}`}
                        src={`https://terracharms.com${image}`}
                        className="h-full w-full object-cover object-center transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                      />
                    </div>
                  ) : null
                ))}
              </div>
            ))
          ) : (
            <p className="text-center w-full">No promotions available</p>
          )}
        </div>
        {/* </div>
        </div> */}
      </div>


        {promotions.length > 0 && (
          <a
            href="#"
            className="inline-block mt-10 ml-12 rounded-md border border-transparent bg-blue-900 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
            onClick={(e) => {
              e.preventDefault();
              handlePromotionClick(promotions[0].sub_category_name_id);
            }}
          >
            Shop Collection
          </a>
        )}
      </div>
    </div>
  </div>
</div>

{/* Mobile Version */}
<div className="relative overflow-hidden bg-gradient-to-r from-sky-100 to-indigo-300 mt-2 ml-5 mr-5 mx-1 sm:mx-1 lg:hidden">
  <div className="mt-2 bg-gradient-to-r from-sky-100 to-indigo-300">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">
      Latest Products
    </h2>
    <div className="mt-2 mb-2">
      <Slider {...mobileSettings}>
        {latestProducts.length > 0 ? (
          latestProducts.map((product) => (
            <div className="p-2" key={product.id}>
              <div
                className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
                onClick={() => handlelatestclick(product.id, product.pk)}
              >
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>

                <div className="mt-4 text-center">
                  <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                    {product.product_name}
                  </h3>
                  <div className="price-container mt-2">
                    <p className="price text-sm text-gray-600">
                      ₹{(product.discount_price * count).toFixed(2)}{" "}
                      <span className="actual-price line-through text-gray-500">
                        ₹{(product.actual_price * count).toFixed(2)}
                      </span>
                    </p>
                    {product.discount_price && product.actual_price && (
                      <span className="discount-box text-sm text-white">
                        {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No latest products available</p>
        )}
      </Slider>
    </div>
  </div>
</div>

{/* Mobile Version */}                 
<div className="relative overflow-hidden bg-gradient-to-r from-sky-100 to-indigo-300 mt-2 ml-5 mb-4 mx-1 sm:mx-1 lg:hidden">
  <div className="pt-2 bg-gradient-to-r from-teal-100 to-teal-300">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">
      Best Seller on Domestic Harmony
    </h2>
    <div className="mt-2 mb-2">
      <Slider {...mobileSettings}>
        {bestsellersProducts.length > 0 ? (
          bestsellersProducts.map((product) => (
            <div className="p-2" key={product.id}>
            <div
              className="group relative bg-white rounded-lg p-4 hover:shadow-xl"
              onClick={() => handlebestsellerclick(product.id, product.pk)}
            >
              <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-white">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>

                <div className="mt-4 text-center">
                  <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                    {product.product_name}
                  </h3>
                  <div className="price-container mt-2">
                    <p className="price text-sm text-gray-600">
                      ₹{(product.discount_price * count).toFixed(2)}{" "}
                      <span className="actual-price line-through text-gray-500">
                        ₹{(product.actual_price * count).toFixed(2)}
                      </span>
                    </p>
                    {product.discount_price && product.actual_price && (
                      <span className="discount-box text-sm text-white">
                        {Math.round(((product.actual_price - product.discount_price) / product.actual_price) * 100)}% OFF
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No bestsellers available</p>
        )}
      </Slider>
    </div>
  </div>
</div>

{/* Mobile Version */}
<div className="relative overflow-hidden bg-gradient-to-r from-sky-100 to-indigo-300 mt-2 ml-5 mr-5 mx-1 sm:mx-1 lg:hidden">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mt-4 mb-2 text-left">
            Top Selection
          </h2>
          <div className="px-1 py-1 grid grid-cols-1 gap-y-4 sm:grid-cols-2 gap-6">
            {callouts.length > 0 ? (
              callouts.map((callout) => (
                <div
                  key={callout.name}
                  className="group relative overflow-hidden bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
                  onClick={() => handleProductClick(callout.id, callout.pk)}
                >
                  <div className="relative h-60 w-full">
                    <img
                      alt={callout.imageAlt}
                      src={callout.imageSrc}
                      className="h-full w-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-black opacity-25 transition-opacity duration-300 group-hover:opacity-0" />
                  </div>
                  <div className="p-4">
                    <h3 className="title text-truncate text-sm sm:text-base font-semibold text-gray-800">
                      <a href={callout.href} className="hover:text-fuchsia-500 transition-colors duration-200">
                        {callout.name}
                      </a>
                    </h3>
                    <div className="mt-2">
                      <p className="text-xl text-left font-bold text-gray-900">
                        ₹{callout.discount_price ? callout.discount_price.toFixed(2) : callout.actual_price.toFixed(2)}
                        <span className="line-through text-gray-500 ml-2">
                          {callout.discount_price ? `₹${callout.actual_price.toFixed(2)}` : ''}
                        </span>
                      </p>
                      {callout.discount_price && callout.actual_price && (
                        <span className="inline-block bg-fuchsia-600 text-white text-sm px-2 py-1 rounded-full mt-1">
                          {Math.round(((callout.actual_price - callout.discount_price) / callout.actual_price) * 100)}% OFF
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button className="bg-fuchsia-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-fuchsia-600 transition-colors duration-300">
                      View Product
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-700">No selections available.</p>
            )}
          </div>
        </div>
</div>


</section>
  </>
);
};
export default Front;
