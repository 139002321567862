import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function OtpPage() {
  const navigate = useNavigate(); // Hook for navigation
  const { pk, name } = useParams(); // Get the primary key and name from the URL
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(0);
  const [showVerifyButton, setShowVerifyButton] = useState(true);
  const [message, setMessage] = useState('');
  const csrftoken = Cookies.get('csrftoken');

  useEffect(() => {
    let countdownInterval;
    if (timer > 0) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(countdownInterval);
      setShowVerifyButton(true);
    }

    return () => clearInterval(countdownInterval);
  }, [timer]);

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.patch(`https://terracharms.com/verify_otp/${pk}/`, {
        otp: otp,
      }, {
        headers: {
          'X-CSRFToken': csrftoken
        }
      });

      if (response.status === 200) {
        navigate(`/front/${pk}/${name}`); // Navigate to a success page with fetchedPk
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else if (error.message) {
        setError(error.message);
      } else {
        setError('An unknown error occurred during OTP verification.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (!pk) {
      setMessage('Invalid request: primary key (pk) is missing.');
      return;
    }

    try {
      const response = await axios.patch(`https://terracharms.com/regenrate_otp/${pk}/`, {}, {
        headers: {
          'X-CSRFToken': csrftoken
        }
      });

      if (response.status === 200) {
        setMessage('OTP has been resent.');
        setTimer(15); // Restart the 15-second timer
        setShowVerifyButton(true); // Show the verify button
      } else {
        setMessage('Failed to resend OTP.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.error);
      } else if (error.message) {
        setMessage(error.message);
      } else {
        setMessage('An unknown error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="mx-auto mt-12 bg-white max-w-2xl px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="h-16 w-16 ml-40"
          src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
          alt="StarkMart"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {`Hello ${name}, Please Verify Your Mobile Number`}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleVerifyOTP}>
          <div>
            <label htmlFor="otp" className="block text-sm font-medium leading-6 text-gray-900">
              OTP
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? 'Verifying...' : 'Verify OTP'}
            </button>
          </div>

          {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
          {message && <p className="mt-4 text-center text-sm text-green-500">{message}</p>}
        </form>

        {showVerifyButton && (
          <button
            onClick={handleResendOTP}
            className="mt-4 w-full justify-center rounded-md bg-gray-300 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200"
          >
            Resend OTP
          </button>
        )}

        {timer > 0 && <p className="mt-4 text-center text-sm text-gray-500">Resend OTP in {timer}s</p>}
      </div>
    </div>
  );
}
